import axios from "@/axios";
const state = {
  payments: {}
};
const getters = {
  allPayments: state => state.payments.data,
  getPayCurrentPage: state => state.payments.current_page,
  getPayLastPage: state => state.payments.last_page
};
const actions = {
  async fetchPayments({ commit }, info) {
    const response = await axios.get(
      "/clinic/payments?per_page=10&&page=" +
        info.page +
        "&&search=" +
        info.keyword
    );
    commit("SET_PAYMENTS", response.data);
  },

 
  setPayCurrentPage({ commit }, currentPage) {
    commit("SET_PAYMENTS_CURRENT_PAGE", currentPage);
  },
 
};
const mutations = {
  SET_PAYMENTS: (state, data) => (state.payments = data),
  SET_PAYMENTS_CURRENT_PAGE: (state, data) =>
    (state.payments.current_page = data),

};

export default {
  state,
  getters,
  actions,
  mutations
};

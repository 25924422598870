<template>
  <v-dialog v-if="OffersDialog" :value="OffersDialog" width="500">
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">
          اضافة عروض جديدة للمراجعين
        </h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-alert dense outlined type="error" v-if="errorAlrt">
          حدث خطاء فشل في العملية
        </v-alert>
        <v-alert dense outlined type="success" v-if="successAlrt">
          عملية الاضافة تمت بنجاح
        </v-alert>
        <v-form ref="offer_form" lazy-validation>
          <v-col cols="12">
            <v-text-field
              label="العنوان"
              v-model="Offer.title"
              :rules="requiredRule"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              label="نص العرض"
              :rules="requiredRule"
              v-model="Offer.body"
              rows="2"
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="Offer.offerPeriod"
              :items="offerPeriods"
              item-text="txt"
              item-value="val"
              label="فترة الاشتراك"
              placeholder="اختر الفترة"
              required
              :rules="requiredRule"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" class="float-right">
            <v-text-field
              label="السعر"
              type="number"
              :rules="requiredRule"
              v-model="Offer.price"
              suffix="دينار عراقي"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="float-right">
            <v-text-field
              label="الخصم بالمئة"
              type="number"
              :rules="requiredRuleNumber"
              v-model="Offer.discount"
              prepend-icon="mdi-percent"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" class="float-right">
            <v-menu
              ref="offerFromDate"
              v-model="dateFromMenu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="Offer.fromDate"
                  label="من تاريخ"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :clearable="trueValue"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="Offer.fromDate"
                :active-picker.sync="activePicker"
                :min="new Date(Date.now()).toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" class="float-right">
            <v-menu
              ref="offerToDate"
              v-model="dateToMenu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="Offer.toDate"
                  label="الى تاريخ"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :clearable="trueValue"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="Offer.toDate"
                :active-picker.sync="activePicker"
                :min="new Date(Date.now()).toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" class="float-right">
            <v-file-input
              accept="image/png, image/jpeg, image/jpg"
              placeholder="اختر الصور"
              prepend-icon="mdi-image"
              label="الصور ان وجدت"
              multiple
              v-model="Offer.images"
            ></v-file-input>
          </v-col>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="indigo white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="addNewOffer"
        >
          حفظ
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>

        <v-btn color="primary" text @click="closeDialog">
          اغلاق
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["OffersDialog"],
  data() {
    return {
      trueValue: true,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      requiredRuleNumber: [
        (v) => {
          if (parseInt(v) >= 0) return true;
          return "هذا الحقل مطلوب";
        },
      ],
      loadingSpinner: false,
      errorAlrt: false,
      successAlrt: false,
      activePicker: null,
      dateFromMenu: false,
      dateToMenu: false,
      Offer: {
        title: null,
        body: null,
        fromDate: null,
        toDate: null,
        discount: 0,
        price: 0,
        offerPeriod: "",
        images: [],
      },
      formData: new FormData(),
      offerPeriods: [
        { val: "once", txt: "لمرة واحدة" },
        { val: "1", txt: "1 شهر" },
        { val: "2", txt: "2 شهر" },
        { val: "3", txt: "3 شهر" },
        { val: "4", txt: "4 شهر" },
        { val: "5", txt: "5 شهر" },
        { val: "6", txt: "6 شهر" },
        { val: "7", txt: "7 شهر" },
        { val: "8", txt: "8 شهر" },
        { val: "9", txt: "9 شهر" },
        { val: "10", txt: "10 شهر" },
        { val: "11", txt: "11 شهر" },
        { val: "12", txt: "12 شهر" },
        { val: "24", txt: "24 شهر" },
        { val: "36", txt: "36 شهر" },
      ],
    };
  },

  methods: {
    ...mapActions(["addOffer"]),
    closeDialog() {
      this.$emit("offersDialogClose", false);
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },
    addNewOffer() {
      if (!this.$refs.offer_form.validate()) return;

      this.formData.append("title", this.Offer.title);
      this.formData.append("body", this.Offer.body);
      this.formData.append("price", this.Offer.price);
      this.formData.append("offer_period", this.Offer.offerPeriod);
      this.formData.append("discount_rate", this.Offer.discount);
      this.formData.append("from_date", this.Offer.fromDate);
      this.formData.append("to_date", this.Offer.toDate);
      for (let i = 0; i < this.Offer.images.length; i++) {
        this.formData.append("images[" + i + "]", this.Offer.images[i]);
      }
      this.loadingSpinner = true;

      this.addOffer(this.formData)
        .then((sc) => {
          console.log(sc);
          if (sc == 200) {
            this.loadingSpinner = false;
            this.successAlrt = true;
            setTimeout(() => {
              this.formData = new FormData();
              this.Offer.title = null;
              this.Offer.body = null;
              this.Offer.fromDate = null;
              this.Offer.toDate = null;
              this.Offer.discount = 0;
              this.Offer.price = 0;
              this.Offer.images = [];
              this.successAlrt = false;
              this.errorAlrt = false;
              this.$refs.offer_form.reset();
              this.closeDialog();
            }, 3000);
          }
        })
        .catch(() => {
          this.errorAlrt = true;
          this.loadingSpinner = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>

import axios from "@/axios";
const state = {
  chronicNotebooks: {},
};

const getters = {
  getChronicNotebook: (state) => state.chronicNotebooks.data,
  getChronicNotebookCurrentPage: (state) => state.chronicNotebooks.current_page,
  getChronicNotebookLastPage: (state) => state.chronicNotebooks.last_page,
};
const actions = {
  async fetchChronicNotebook({ commit }, info) {
    const response = await axios.get(
      "/clinic/patient-chronic-notebook?per_page=10&&page=" +
        info.page +
        "&&search=" +
        info.keyword
    );
    commit("SET_CHRONIC_NOTEBOOK", response.data);
  },
  async getPatientChronicDrugs(_, patientId) {
    const response = await axios.get(
      "/clinic/patients/" + patientId + "/patient-chronic-drugs"
    );
    if (response.status == 200) {
      return response.data;
    }
  },

  async deletePatientChronicDrug({ commit }, info) {
    const response = await axios.post(
      "/clinic/patient-chronic-notebooks/delete-drug",
      info
    );
    if (response.status == 200) {
      commit("DELETE_CHRONIC_DRUG", info);
      return "success";
    }
  },
  async deleteNotebook({ commit }, info) {
    const response = await axios.post(
      "/clinic/patient-chronic-notebooks/" + info.id,
      info
    );
    if (response.status == 200) {
      commit("DELETE_NOTEBOOK", info.id);
      return "success";
    }
  },
  async updatePatientProfilePicture({ commit }, info) {
    const response = await axios.post(
      "/clinic/patients/" + info.get("id"),
      info
    );
    if (response.status == 200) {
      console.log(info.get("route"));
      if (info.get("route") == "/dashboard/chronicBook")
        commit("UPDATE_PATIENT_DATA", response.data.data);
      else commit("UPDATE_PATIENT", response.data.data);

      return "success";
    }
  },
  async addPatientChronicDrugs({ commit }, info) {
    const response = await axios.post("/clinic/patient-chronic-notebook", info);
    if (response.status == 201) {
      commit("ADD_NOTEBOOK", response.data.data);
      return "success";
    }
    console.log(response);
  },

  async addChronicDrugToNotebook({ commit }, info) {
    const response = await axios.post(
      "/clinic/assign-clinic-drug-to-patient",
      info
    );
    if (response.status == 200) {
      commit("ADD_CHRONIC_TO_NOTEBOOK", response.data.data);
      return "success";
    }
    console.log(response);
  },
  setChronicCurrentPage({ commit }, currentPage) {
    commit("SET_CHRONIC_NOTEBOOK_CURRENT_PAGE", currentPage);
  },
};
const mutations = {
  SET_CHRONIC_NOTEBOOK: (state, data) => (state.chronicNotebooks = data),
  UPDATE_PATIENT_DATA: (state, data) => {
    state.chronicNotebooks.data.forEach((item, i) => {
      if (item.patient.id == data.id) {
        state.chronicNotebooks.data[i].patient.profile_picture =
          data.profile_picture;
      }
    });
  },
  ADD_CHRONIC_TO_NOTEBOOK: (state, data) => {
    const notebookIndex = state.chronicNotebooks.data.findIndex(
      (item) => item.id === data.notebookId
    );
    state.chronicNotebooks.data[notebookIndex].clinic_drugs.push(data);
  },

  DELETE_NOTEBOOK: (state, id) => {
    const notebookIndex = state.chronicNotebooks.data.findIndex(
      (item) => item.id === id
    );

    if (notebookIndex !== -1) {
      state.chronicNotebooks.data.splice(notebookIndex, 1);
    }
  },
  DELETE_CHRONIC_DRUG: (state, data) => {
    const notebookIndex = state.chronicNotebooks.data.findIndex(
      (item) => item.id === data.pivot.notebookId
    );

    if (notebookIndex !== -1) {
      const drugIndex = state.chronicNotebooks.data[
        notebookIndex
      ].clinic_drugs.findIndex((drug) => drug.id === data.clinicDrugId);

      if (drugIndex !== -1) {
        state.chronicNotebooks.data[notebookIndex].clinic_drugs.splice(
          drugIndex,
          1
        );
      } else {
        console.error("Drug not found in the notebook.");
      }
    } else {
      console.error("Notebook containing the drug not found.");
    }
  },

  SET_CHRONIC_NOTEBOOK_CURRENT_PAGE: (state, data) =>
    (state.chronicNotebooks.current_page = data),
  ADD_NOTEBOOK: (state, data) => {
    state.chronicNotebooks.data.unshift(data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

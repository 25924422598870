import { render, staticRenderFns } from "./PatientProfile.vue?vue&type=template&id=96089e62&scoped=true"
import script from "./PatientProfile.vue?vue&type=script&lang=js"
export * from "./PatientProfile.vue?vue&type=script&lang=js"
import style0 from "./PatientProfile.vue?vue&type=style&index=0&id=96089e62&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96089e62",
  null
  
)

export default component.exports
<template>
  <div class="advices">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-1" style="font-family: cairo!important;">
        النصائح الطبية
      </div>

      <v-card class="pb-1">
        <v-card-title class="">
          <v-row dense>
            <v-col cols="12">
              <v-btn
                class="float-right ml-2 mb-2"
                color="green white--text"
                @click="AddMedicalAdviceDialog = true"
              >
                <v-icon left>mdi-plus</v-icon>
                اضافة نصيحة
              </v-btn>
              <v-text-field
                dense
                v-model="Info.keyword"
                append-icon="mdi-magnify"
                label="بحث"
                hide-details
                solo
                class="d-inline-block mt-0"
                style="vertical-align:center;margin-top:0"
              >
                <template v-slot:append>
                  <v-btn
                    :loading="loadingSearch"
                    :disabled="loadingSearch"
                    color="blue darken-1"
                    plain
                    class="float-right "
                    @click="searchInAdvices()"
                  >
                    <v-icon left>mdi-magnify</v-icon>

                    بحث
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          align-center
          :headers="headers"
          :items="allAdvices"
          :items-per-page="itemPerPage"
          hide-default-footer
          fixed-header
          dir="rtl"
          style="overflow: hidden!important"
        >
          <!-- <template v-slot:item.deleteAdvice="{ item }">
            <v-btn icon color="red" class="mx-2" @click="deleteAdvice(item.id)">
              <v-icon dark>mdi-trash-can</v-icon>
            </v-btn>
          </template> -->
          <template v-slot:item.date="{ item }">
            {{ item.created_at.substring(0, 10) }}
          </template>
          <template v-slot:item.shortenBody="{ item }">
            <span @click="showFullbody(item.body)">
              {{ truncate(item.body, 100) }}</span
            >
          </template>
          <template v-slot:item.editMedicalAdvice="{ item }">
            <v-btn @click="editMedicalAdvice(item.id)" text small>
              <v-icon color="amber">mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          :disabled="disabledPagination"
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
        ></v-pagination>
        <!-- pagination -->
      </v-card>
    </v-container>
    <!--  <delete-advice
      :id="adviceId"
      :DeleteAdviceDialog="DeleteAdviceDialog"
      @deleteAdviceDialogClose="DeleteAdviceDialog = $event"
    /> -->
    <add-medical-advice
      :AddMedicalAdviceDialog="AddMedicalAdviceDialog"
      @addMedicalAdviceDialogClose="AddMedicalAdviceDialog = $event"
    />
    <edit-medical-advice
      :EditMedicalAdviceDialog="EditMedicalAdviceDialog"
      :medicalAdviceDetails="medicalAdviceData"
      @editMedicalAdviceDialogClose="editMedicalAdviceDialogClosed($event)"
    />
    <full-text-dialog
      :FullTextDialog="FullTextDialog"
      :text="text"
      @fullTextDialogClose="FullTextDialog = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddMedicalAdvice from "../../components/models/medical_advice/AddMedicalAdvice";
import EditMedicalAdvice from "../../components/models/medical_advice/EditMedicalAdvice";
import FullTextDialog from "../../components/models/global/FullTextDialog";
export default {
  name: "MedicalAdvicesPage",
  components: { AddMedicalAdvice, FullTextDialog, EditMedicalAdvice },
  watch: {
    currentGet(newVal) {
      this.Info.page = newVal;
      this.disabledPagination = true;
      this.fetchAdvices(this.Info)
        .then(() => {
          this.disabledPagination = false;
        })
        .catch(() => {
          this.disabledPagination = false;
        });
    },
  },

  computed: {
    ...mapGetters(["allAdvices", "getMedicalAdvice"]),

    currentGet: {
      get() {
        return this.$store.getters.getAdvicesCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setAdvicesCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getAdvicesLastPage;
      },
    },
  },

  data() {
    return {
      /*  adviceId: 0,
      DeleteAdviceDialog: false, */
      disabledPagination: false,
      AddMedicalAdviceDialog: false,
      medicalAdviceData: {},
      EditMedicalAdviceDialog: false,
      loadingSearch: false,
      headers: [
        { text: "العنوان", value: "title" },
        { text: "النص", value: "shortenBody" },
        { text: "القسم", value: "category_info.name" },
        { text: "likes", value: "likes_count" },
        { text: "loves", value: "loves_count" },
        { text: "تاريخ النشر", value: "date" },
        { text: "تعديل", value: "editMedicalAdvice" },
      ],
      itemPerPage: 10,
      selectedPage: 1,
      FullTextDialog: false,
      text: "",
      Info: {
        page: 1,
        keyword: "",
      },
    };
  },
  created() {
    this.disabledPagination = true;
    this.fetchAdvices(this.Info)
      .then(() => (this.disabledPagination = false))
      .catch(() => (this.disabledPagination = false));
  },
  methods: {
    ...mapActions(["fetchAdvices"]),
    showFullbody(body) {
      this.FullTextDialog = true;
      this.text = body;
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + " ..." : str;
    },
    editMedicalAdviceDialogClosed(state) {
      this.EditMedicalAdviceDialog = state;
      this.medicalAdviceData = {};
    },
    editMedicalAdvice(id) {
      this.medicalAdviceData = this.getMedicalAdvice(id);
      this.EditMedicalAdviceDialog = true;
    },
    searchInAdvices() {
      this.Info.page = 1;
      this.loadingSearch = true;
      this.disabledPagination = true;
      this.fetchAdvices(this.Info)
        .then(() => {
          this.disabledPagination = false;
          this.loadingSearch = false;
        })
        .catch(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        });
    },
    /*   deleteAdvice(id) {
      this.adviceId = id;
      this.DeleteAdviceDialog = true;
      
    }, */
  },
};
</script>

<style lang="scss" scoped>
.advices {
  max-height: 82vh;
  overflow: auto;
  .noScroll {
    overflow: hidden !important;
  }
}
</style>

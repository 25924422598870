import axios from "@/axios";
const state = {
  notifications: {}
};
const getters = {
  allNotifications: state => state.notifications.data,
  getNotifCurrentPage: state => state.notifications.current_page,
  getNotifLastPage: state => state.notifications.last_page
};
const actions = {
  async fetchNotifications({ commit }, info) {
    const response = await axios.get(
      "/clinic/notifications?per_page=10&&page=" +
        info.page +
        "&&search=" +
        info.keyword
    );
    commit("SET_NOTIFICATIONS", response.data);
  },

  async sendSingleNotification(_, info) {
    const response = await axios.post("/clinic/notifications", {
      title: info.title,
      body: info.body,
      patientId: info.patientId,
      type: "patient"
    });
    return response.status;
  },
  async sendNewNotification(_, info) {
    const response = await axios.post("/clinic/notifications", {
      title: info.title,
      body: info.body,
      type: "patient"
    });
    return response.status;
  },
  setNotifCurrentPage({ commit }, currentPage) {
    commit("SET_NOTIFICATIONS_CURRENT_PAGE", currentPage);
  },
 
};
const mutations = {
  SET_NOTIFICATIONS: (state, data) => (state.notifications = data),
  SET_NOTIFICATIONS_CURRENT_PAGE: (state, data) =>
    (state.notifications.current_page = data),

};

export default {
  state,
  getters,
  actions,
  mutations
};

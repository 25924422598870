import axios from "@/axios";
const state = {
  counters: {}
};

const getters = {
  getCounters: state => state.counters
};
const actions = {
  async fetchCounters({ commit }) {
    const response = await axios.get("/clinic/counters");
    commit("SET_COUNTERS", response.data.data);
  }
};
const mutations = {
  SET_COUNTERS: (state, counters) => (state.counters = counters)
};

export default {
  state,
  getters,
  actions,
  mutations
};
<template>
  <v-dialog
    :value="PatientChronicDrugs"
    v-if="PatientChronicDrugs"
    :width="$vuetify.breakpoint.smAndDown ? '90%' : '85%'"
    persistent
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">الادوية المزمنة</h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <div class="text-right px-2">
          <h6>اسم المريض</h6>
          <h3>{{ patientData.fullname }}</h3>
          <h6>فصيلة الدم</h6>
          <h3>{{ patientData.blood_type }}</h3>
          <h6>الحساسية للادوية</h6>
          <h3>
            {{
              patientData.drug_allergy == null
                ? " لايوجد"
                : patientData.drug_allergy
            }}
          </h3>
        </div>
        <v-divider class="my-2"></v-divider>
        <v-form ref="prescription_form" lazy-validation>
          <v-container>
            <v-row class="mb-2">
              <v-col cols="12" md="5">
                <v-autocomplete
                  clearable
                  @click:clear="clearAutocompleteFields"
                  @input="updatePrescriptionFields"
                  v-model="drugId"
                  :search-input.sync="searchMedicine"
                  :items="getMedicines('chronic')"
                  label="اسم العلاج"
                  placeholder="بحث عن الاسم الطبي"
                  required
                  append-outer-icon="mdi-refresh"
                  @click:append-outer="fetchClinicMedFn"
                  outlined
                  item-text="drugNameIndexed"
                  item-value="id"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="الجرعة"
                  placeholder="example:500mg"
                  v-model="dose"
                  outlined
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete
                  v-model="route"
                  :items="drugRoutes"
                  label="الشكل الدوائي"
                  placeholder="Route"
                  required
                  outlined
                  readonly
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="quantity"
                  label="الكمية"
                  required
                  dir="ltr"
                  outlined
                  ref="qunatity_ref"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn
                  @click="addChronicDrugToNotebookFn"
                  large
                  color="green lighten-1 white--text"
                  :loading="loadingSpinner"
                  :disabled="loadingSpinner"
                >
                  <v-icon dark> mdi-plus </v-icon>
                  حفظ
                </v-btn>
              </v-col>
              <v-col cols="12 text-right">الادوية المزمنة</v-col>

              <v-col
                v-for="(prescription, index) in drugsData"
                :key="index"
                class="ml-2 px-2 py-2 mb-2"
                style="
                  position: relative;
                  border: 1px solid #2196f3;
                  border-radius: 5px;
                  color: #2196f3;
                  font-size: 15px;
                "
                cols="12"
                md="4"
              >
                <div class="text-right">
                  <div>{{ prescription.drug_name }}</div>
                  <div>الكمية: {{ prescription.pivot.quantity }}</div>
                  <v-icon
                    @click="deleteChronicDrugFn(prescription)"
                    color="red"
                    class="remove-prescription"
                    :loading="loadingSpinnerDel"
                    :disabled="loadingSpinnerDel"
                    >mdi-close-box</v-icon
                  >
                </div>
              </v-col>
            </v-row>
            <v-alert dense outlined type="error" v-if="errorAlrt">
              {{ errorMsg }}
            </v-alert>
            <v-alert dense outlined type="success" v-if="successAlrt">
              العملية تمت بنجاح
            </v-alert>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="snackbar">
      الرجاء ملئ جميع الحقول
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    props: ["patientData", "drugsData", "PatientChronicDrugs", "notebookId"],
    data() {
      return {
        snackbar: false,
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        loadingSpinner: false,
        loadingSpinnerDel: false,
        errorMsg: null,
        errorAlrt: false,
        successAlrt: false,
        drugId: 0,
        drug: "",
        route: "",
        dose: "",
        drugRoutes: [
          "syrup",
          "bottle",
          "vial",
          "ampule",
          "tablet",
          "capsule",
          "liquid",
          "sachet",
          "cream",
          "gel",
          "soap",
          "spray",
          "drops",
          "plaster",
          "paste",
          "patch",
          "inhalation",
          "solution",
          "suspension",
          "suppository",
        ],
        quantity: "",
        searchMedicine: "",
      };
    },
    created() {
      if (this.isGovClinic) {
        this.fetchClinicMedicines();
      }
    },
    computed: {
      ...mapGetters(["getMedicines", "isGovClinic", "getMedicineById"]),
    },
    methods: {
      ...mapActions([
        "fetchClinicMedicines",
        "deletePatientChronicDrug",
        "addChronicDrugToNotebook",
      ]),
      deleteChronicDrugFn(drug) {
        console.log("::", drug);
        const info = {
          notebookId: drug.pivot.patient_chronic_notebook_id,
          clinicDrugId: drug.pivot.clinic_drug_id,
          _method: "delete",
        };
        this.loadingSpinnerDel = true;
        this.deletePatientChronicDrug(info)
          .then(() => {
            this.loadingSpinnerDel = false;
            this.errorAlrt = false;
            this.successAlrt = true;

            setTimeout(() => {
              this.successAlrt = false;
            }, 2500);
          })
          .catch(() => {
            this.errorMsg = "حدث خطاء فشل في العملية!";
            this.errorAlrt = true;
            this.loadingSpinnerDel = false;
          });
      },
      updatePrescriptionFields() {
        if (this.drugId) {
          var selectedMedicine = this.getMedicineById(this.drugId);

          console.log("selectedMedicine", selectedMedicine);
          // Check if the selectedMedicine is not null or undefined
          if (selectedMedicine) {
            this.dose = selectedMedicine.dosage || "";
            this.route = selectedMedicine.drug_form || "";
            this.drug = selectedMedicine.drug_name || "";
          }
          this.$refs.qunatity_ref.focus();
        }
      },
      clearAutocompleteFields() {
        // Reset other fields when autocomplete is manually cleared
        console.log("Autocomplete clear");
        this.drug = "";
        this.dose = "";
        this.route = "";
      },

      fetchClinicMedFn() {
        if (this.isGovClinic) {
          this.fetchClinicMedicines();
        }
      },

      handleClickOutSide(e) {
        if (e.target.className == "v-overlay__scrim") this.closeDialog();
      },
      closeDialog() {
        this.$emit("patientChronicDrugsClose", false);
      },
      addChronicDrugToNotebookFn() {
        if (!this.drugId || !this.route || !this.quantity) {
          this.snackbar = true;
          return;
        }
        // Check if the quantity is a valid number
        if (isNaN(this.quantity)) {
          this.errorAlrt = true;
          this.errorMsg = "يجب ان تكون الكمية رقم!";

          this.loadingSpinner = false;
          return;
        }

        const data = {
          clinicDrugId: this.drugId,
          quantity: this.quantity,
          notebookId: this.notebookId,
        };

        this.loadingSpinner = true;
        this.addChronicDrugToNotebook(data)
          .then(() => {
            this.loadingSpinner = false;
            this.successAlrt = true;
            this.$refs.prescription_form.reset();

            setTimeout(() => {
              this.successAlrt = false;
              this.errorAlrt = false;
            }, 2500);
          })
          .catch(() => {
            this.errorMsg = "حدث خطاء فشل في العملية!";

            this.errorAlrt = true;
            this.loadingSpinner = false;
          });
      },
      removePrescreptionFromList(i) {
        this.currentPrescription.splice(i, 1);
      },
    },
  };
</script>

<style scoped>
  .ltr-menu {
    direction: ltr !important;
    color: red;
  }
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  .remove-prescription {
    position: absolute;
    left: 2rem;
    top: 35%;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
</style>

import axios from "@/axios";
const state = {
  clinic: {}
};

const getters = {
  getClinic: state => state.clinic
};
const actions = {
  async setClinic({ commit }, id) {
    const response = await axios.get("/clinic/clinics/" + id);
    commit("SET_CLINIC", response.data);
  },

  async updateClinicData({ commit }, clinic) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post("/clinic/clinics/" + clinic.get("id"), clinic);

    if (response.data.success == "true")
      commit("UPDATE_CLINIC", response.data.data);
    return response.data.message;
  }
};
const mutations = {
  SET_CLINIC: (state, data) => (state.clinic = data),
  UPDATE_CLINIC: (state, data) => {
    state.clinic = data;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

<template>
  <v-dialog
    v-if="AddMedicalAdviceDialog"
    :value="AddMedicalAdviceDialog"
    width="500"
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">
          اضافة نصحية طبية
        </h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-alert dense outlined type="error" v-if="errorAlrt">
          حدث خطاء فشل في العملية
        </v-alert>
        <v-alert dense outlined type="success" v-if="successAlrt">
          عملية الاضافة تمت بنجاح
        </v-alert>
        <v-form ref="medical_advice_form" lazy-validation>
          <v-text-field
            label="عنوان النصيحة"
            v-model="Info.title"
            :rules="requiredRule"
            class="mb-2"
          >
          </v-text-field>
          <v-textarea
            label="الوصف"
            :rules="requiredRule"
            v-model="Info.body"
            rows="3"
            class="mb-2"
          ></v-textarea>

          <v-select
            :rules="requiredRule"
            v-model="Info.categoryId"
            item-text="name"
            item-value="id"
            :items="allAdvicesCategories"
            label="اختر القسم"
          ></v-select>
          <v-file-input
            accept="image/png, image/jpeg, image/jpg"
            placeholder="اختر الصور"
            prepend-icon="mdi-image"
            label="الصور ان وجدت"
            multiple
            v-model="Info.images"
          ></v-file-input>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="indigo white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="addNewAdvice"
        >
          اضافة
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
        <v-btn color="primary" text @click="closeDialog">
          اغلاق
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["AddMedicalAdviceDialog"],
  data() {
    return {
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      loadingSpinner: false,
      errorAlrt: false,
      successAlrt: false,
      Info: { title: null, body: null, categoryId: null, images: [] },
      formData: new FormData(),
    };
  },
  mounted() {
    this.fetchAdviceCategories();
  },
  computed: {
    ...mapGetters(["allAdvicesCategories"]),
  },
  methods: {
    ...mapActions(["addMedicalAdvice", "fetchAdviceCategories"]),
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },
    closeDialog() {
      this.$emit("addMedicalAdviceDialogClose", false);
    },
    addNewAdvice() {
      if (!this.$refs.medical_advice_form.validate()) return;
      this.loadingSpinner = true;
      this.formData.append("title", this.Info.title);
      this.formData.append("body", this.Info.body);
      this.formData.append("category_id", this.Info.categoryId);
      for (let i = 0; i < this.Info.images.length; i++) {
        this.formData.append("images[" + i + "]", this.Info.images[i]);
      }

      this.addMedicalAdvice(this.formData)
        .then(() => {
          this.loadingSpinner = false;
          this.successAlrt = true;
          this.formData = new FormData();
          setTimeout(() => {
            this.Info.title = null;
            this.Info.body = null;
            this.Info.categoryId = null;
            this.Info.images = [];
            this.successAlrt = false;
            this.errorAlrt = false;
            this.$refs.medical_advice_form.reset();
            this.closeDialog();
          }, 2500);
        })
        .catch(() => {
          this.errorAlrt = true;
          this.loadingSpinner = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>

import axios from "@/axios";
const state = {
  offers: {}
};

const getters = {
  allOffers: state => state.offers.data,
  getOffer: (state) => (id) => {
    var offer = state.offers.data.find((offer) => offer.id === id);
    return offer;
  }, 
  getOffersCurrentPage: state => state.offers.current_page,
  getOffersLastPage: state => state.offers.last_page
};
const actions = {
  async fetchOffers({ commit }, info) {
    const response = await axios.get(
      "/clinic/offers?per_page=10&&page=" +
        info.page +
        "&&search=" +
        info.keyword
    );
    commit("SET_OFFERS", response.data);
  },
  async addOffer({ commit }, offer) {
    const response = await axios.post("/clinic/offers", offer);
    if(response.status==200)
    commit("ADD_OFFER", response.data);
    return response.status;
  },
  async updateOffer({ commit }, offer) {
    const response = await axios.post("/clinic/offers/" + offer.get("id"), offer);
    if (response.data.success == "true")
      commit("UPDATE_OFFER", response.data.data);
    return response.data.message;
  },
  async deleteOffer({ commit }, id) {
    const response = await axios.post("/clinic/offers/" + id, {
      _method: "delete"
    });
    if (response.data.message == "deleted") {
      commit("DELETE_OFFER", id);
      return "deleted";
    } else return "failed";
  },
  async toggleOfferState({ commit }, id) {
    const response = await axios.post("/clinic/offers/" + id+"/changeStatus");
    if (response.data.message == "updated") {
      commit("CHANGE_OFFER_STATUS", response.data.data);
      return "updated";
    } else return "failed";
  },
  setOffersCurrentPage({ commit }, currentPage) {
    commit("SET_OFFERS_CURRENT_PAGE", currentPage);
  },
 
};
const mutations = {
  SET_OFFERS: (state, data) => (state.offers = data),
  SET_OFFERS_CURRENT_PAGE: (state, data) => (state.offers.current_page = data),
  DELETE_OFFER: (state, id) => {
    const i = state.offers.data.map(item => item.id).indexOf(id);
    state.offers.data.splice(i, 1);
  },
  CHANGE_OFFER_STATUS: (state, data) => {
    const i = state.offers.data.map(item => item.id).indexOf(data.id);
    state.offers.data[i].is_hidden=data.is_hidden;
  },
  ADD_OFFER: (state, data) => {
    state.offers.data.unshift(data);
  },
  UPDATE_OFFER: (state, data) => {
    const i = state.offers.data.map(item => item.id).indexOf(data.id);
    state.offers.data[i].title=data.title;
    state.offers.data[i].body=data.body;
    state.offers.data[i].from_date=data.from_date;
    state.offers.data[i].to_date=data.to_date;
    state.offers.data[i].discount_rate=data.discount_rate;
    state.offers.data[i].price=data.price;
    state.offers.data[i].images=data.images;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};

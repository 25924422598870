<template>
  <v-dialog :value="AddCreditCardDialog" v-if="AddCreditCardDialog" width="450">
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="mx-auto pr-1">
          اضافة بطاقة دفع
        </h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-container fluid>
          <v-alert dense outlined type="error" v-if="errorAlrt">
            {{ errorAlrtText }}
          </v-alert>
          <v-alert dense outlined type="success" v-if="successAlrt">
            العملية تمت بنجاح
          </v-alert>
          <v-form ref="new_credit_form" lazy-validation>
            <v-row class="text-right">
              <v-col cols="12">
                <v-select
                  v-model="Info.cardType"
                  :items="getCardTypes"
                  item-text="arabic_name"
                  item-value="id"
                  label="نوع البطاقة"
                  placeholder="اختر النوع"
                  required
                  :rules="requiredRule"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="اسم حامل البطاقة"
                  outlined
                  :rules="requiredRule"
                  v-model="Info.holderFullname"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  :rules="requiredRule"
                  v-model="Info.cardNumber"
                  placeholder="رقم البطاقة"
                  label="رقم البطاقة"
                  type="number"
                  maxlength="16"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="Info.cardType == 2">
                <v-text-field
                  outlined
                  :rules="requiredRule"
                  v-model="Info.cardAccountNumber"
                  placeholder="رقم الحساب"
                  label="رقم الحساب"
                  maxlength="10"
                  minlength="10"
                  type="number"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" v-if="Info.cardType == 2">
                <v-menu
                  ref="expiryDateMenu"
                  v-model="expiryDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="Info.cardExpiryDate"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="Info.cardExpiryDate"
                      label="تاريخ الانتهاء"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="Info.cardExpiryDate"
                    type="month"
                    no-title
                    scrollable
                  >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.expiryDateMenu.save(Info.cardExpiryDate)"
                    >
                      اختيار
                    </v-btn>
                    <v-btn text color="primary" @click="expiryDateMenu = false">
                      الغاء
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="indigo darken-1 white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="addCreditCard"
        >
          حفظ
        </v-btn>
        <v-btn color="primary" text @click="closeDialog">
          اغلاق
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      الرجاء ملئ جميع الحقول
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["AddCreditCardDialog"],
  data() {
    return {
      expiryDateMenu: false,
      mobileRule: [
        (v) =>
          /^7[3-9][0-9]{8}/.test(v) ||
          "اكتب رقم الهاتف بصورة صحيحة وبدون صفر في البداية",
      ],
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      snackbar: false,
      loadingSpinner: false,
      errorAlrt: false,
      errorAlrtText: "حدث خطاء, فشل في الاضافة",
      successAlrt: false,
      Info: {
        holderFullname: "",
        cardNumber: "",
        cardAccountNumber: "",
        cardExpiryDate: "",
        cardType: null,
      },
    };
  },
  computed: {
    ...mapGetters(["getCardTypes"]),
  },
  mounted() {
    this.fetchCardTypes();
  },
  methods: {
    ...mapActions(["newCreditCard", "fetchCardTypes"]),

    save(date) {
      this.$refs.expiryDateMenu.save(date);
    },
    addCreditCard() {
      if (!this.$refs.new_credit_form.validate()) return;
      this.loadingSpinner = true;

      this.newCreditCard(this.Info)
        .then(() => {
          this.successAlrt = true;
          this.errorAlrt = false;
          this.loadingSpinner = false;
          setTimeout(() => {
            this.successAlrt = false;
            this.$refs.new_credit_form.reset();
            this.closeDialog();
          }, 3500);
        })
        .catch((err) => {
          if (err.response.data.errors.cardNumber[0] == "validation.luhn")
            this.errorAlrtText = "رقم البطاقة الذي ادخلته غير صالح";
          else if (
            err.response.data.errors.cardNumber[0] ==
            "The card number has already been taken."
          )
            this.errorAlrtText = "رقم البطاقة مسجل مسبقا";
          else this.errorAlrtText = "حدث خطاء, فشل في الاضافة";
          this.loadingSpinner = false;
          this.successAlrt = false;
          this.errorAlrt = true;
        });
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },
    closeDialog() {
      this.$emit("addCreditCardDialogClose", false);
    },
  },
};
</script>

<style scoped lang="scss">
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>

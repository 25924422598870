import axios from "@/axios";
const state = {
  cardTypes: []
};

const getters = {
  getCardTypes: state => state.cardTypes
};
const actions = {
  async fetchCardTypes({ commit }) {
    const response = await axios.get("/clinic/creditTypes");
    commit("SET_CARD_TYPES", response.data);
  },
  async newCreditCard({ commit },info) {
    const response = await axios.post("/clinic/creditCards",info);
    if (response.status == 200)
    commit("SET_USER_CREDIT_CARD", response.data);
  }
};
const mutations = {
  SET_CARD_TYPES: (state, data) => (state.cardTypes = data),
};

export default {
  state,
  getters,
  actions,
  mutations
};

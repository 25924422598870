<template>
  <v-dialog
    v-if="ActivateOfferSubscriptionDialog"
    :value="ActivateOfferSubscriptionDialog"
    width="450"
    persistent
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">
          الدفع والتفعيل:
          <span style="font-weight: 100; font-size: 16px">
            {{ offerInfo.title }}</span
          >
        </h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-alert dense outlined type="error" v-if="errorAlrt">
          {{ errorAlrtText }}
        </v-alert>
        <v-alert dense outlined type="success" v-if="successAlrt">
          العملية تمت بنجاح
        </v-alert>
        <v-form v-if="step == 2" ref="otp_form" lazy-validation>
          <v-avatar color="grey lighten-3" size="72" class="mb-6">
            <span class="black--text text-h5">{{ counter }}</span>
          </v-avatar>
          <v-text-field
            v-model="Info.otp"
            label="رمز التاكيد"
            outlined
            dense
            :rules="requiredRule"
          ></v-text-field>
          <v-btn
            block
            color="indigo white--text"
            :loading="loadingSpinner"
            :disabled="loadingSpinner"
            @click="activateSubscription"
          >
            حفظ وتاكيد العملية
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-form>
        <v-form v-if="step == 1" ref="subscription_form" lazy-validation>
          <v-select
            item-text="text"
            item-value="val"
            :items="paymentMethods"
            label="طريقة الدفع"
            v-model="Info.paymentMethod"
            outlined
            :rules="requiredRule"
          >
          </v-select>

          <v-select
            v-if="Info.paymentMethod == 'installment'"
            :items="patientTypes"
            item-text="text"
            item-value="val"
            label="نوع المراجع"
            v-model="Info.patientType"
            outlined
            :rules="requiredRule"
          >
          </v-select>
          <v-select
            v-if="Info.paymentMethod == 'installment'"
            v-model="Info.cardType"
            :items="getCardTypes"
            item-text="arabic_name"
            item-value="id"
            label="نوع البطاقة"
            placeholder="اختر النوع"
            required
            :rules="requiredRule"
            outlined
          ></v-select>
          <v-select
            v-if="Info.paymentMethod == 'installment'"
            :items="months"
            label="عدد اشهر التقسيط"
            v-model="Info.months"
            outlined
            :rules="requiredRule"
          >
          </v-select>
          <v-text-field
            v-if="Info.paymentMethod == 'installment'"
            outlined
            :rules="requiredRule"
            v-model="Info.cardNumber"
            placeholder="رقم البطاقة"
            label="رقم البطاقة"
            type="number"
            maxlength="16"
          ></v-text-field>

          <v-text-field
            v-if="Info.paymentMethod == 'installment' && Info.cardType == 2"
            outlined
            :rules="requiredRule"
            v-model="Info.cardAccountNumber"
            placeholder="رقم الحساب"
            label="رقم الحساب"
            maxlength="10"
            minlength="10"
            type="number"
          ></v-text-field>

          <v-text-field
            label="المبلغ بعد التخفيض"
            type="number"
            v-model="amountWithDisc"
            outlined
            readonly
            :rules="requiredRule"
          >
            <template v-slot:append>
              <v-icon color="green">mdi-currency-usd </v-icon>
            </template>
          </v-text-field>

          <v-btn
            block
            color="indigo white--text"
            :loading="loadingSpinner"
            :disabled="loadingSpinner"
            @click="activateSubscription"
          >
            حفظ
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: [
    "ActivateOfferSubscriptionDialog",
    "subId",
    "paymentMethod",
    "offerInfo",
  ],
  watch: {
    paymentMethod(newVal) {
      if (newVal != null) this.Info.paymentMethod = newVal;
    },
  },
  computed: {
    ...mapGetters(["getCardTypes"]),
    amountWithDisc() {
      return (
        this.offerInfo.price -
        (this.offerInfo.price * this.offerInfo.discount_rate) / 100
      );
    },
  },
  created() {
    this.fetchCardTypes();
  },
  data() {
    return {
      c: "",
      counter: 120,
      step: 1,
      states: [
        { val: "waiting", name: "انتظار" },
        { val: "closed", name: "تم" },
        { val: "canceled", name: "ملغى" },
      ],
      paymentMethods: [
        { val: "cash", text: "نقد" },
        { val: "installment", text: "تقسيط" },
      ],
      patientTypes: [
        { val: 1, text: "متقاعد" },
        { val: 2, text: "موظف" },
      ],
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      loadingSpinner: false,
      errorAlrt: false,
      successAlrt: false,
      Info: {
        subId: 0,
        paymentMethod: this.paymentMethod,
        amount: null,
        months: null,
        patientType: null,
        cardType: null,
        cardNumber: null,
        cardAccountNumber: null,
        otp: null,
        sessionId: null,
      },
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 18, 24, 36],
      errorAlrtText: "حدث خطاء فشل في العملية ",
    };
  },

  methods: {
    ...mapActions(["activateOfferSubscription", "fetchCardTypes"]),

    activateSubscription() {
      if (this.step == 1) {
        if (!this.$refs.subscription_form.validate()) return;
      } else if (this.step == 2) {
        if (!this.$refs.otp_form.validate()) return;
      }

      this.Info.subId = this.subId;
      this.Info.amount = this.amountWithDisc;
      this.loadingSpinner = true;
      this.activateOfferSubscription(this.Info)
        .then((response) => {
          if (response.success == "true" && response.message == "enterOtp") {
            this.loadingSpinner = false;
            this.successAlrt = false;
            this.errorAlrt = false;
            this.Info.sessionId = response.sessionId;
            this.step = 2;
            this.c = setInterval(() => {
              if (this.counter > 0) {
                this.counter--;
                if (this.counter < 10) {
                  this.counter = ("0" + this.counter).slice(-2);
                }
              } else {
                this.step = 1;
                this.counter = 120;
                clearInterval(this.c);
                this.Info.otp = null;
                this.Info.sessionId = null;
              }
            }, 1000);
          } else if (
            response.success == true &&
            response.message == "created"
          ) {
            this.loadingSpinner = false;
            this.step = 1;
            this.counter = 120;
            clearInterval(this.c);
            this.Info.otp = null;
            this.Info.sessionId = null;
            this.successAlrt = true;
            this.errorAlrt = false;
            setTimeout(() => {
              this.successAlrt = false;
              this.errorAlrt = false;
              this.closeDialog();
            }, 3000);
          } else if (response.message == "updated") {
            this.loadingSpinner = false;
            this.Info.otp = null;
            this.Info.sessionId = null;
            this.step = 1;
            this.successAlrt = true;
            this.errorAlrt = false;
            setTimeout(() => {
              this.successAlrt = false;
              this.errorAlrt = false;
              this.closeDialog();
            }, 3000);
          }
        })
        .catch((err) => {
          if (!err.response)
            this.errorAlrtText = "مشكلة في خدمات التقسيط يرجى المحاولة لاحقا";
          else if (
            err.response.status == 504 ||
            err.response.status == 500 ||
            err.response.status == 503 ||
            err.response.status == 501
          )
            this.errorAlrtText = "مشكلة في خدمات التقسيط يرجى المحاولة لاحقا";
          else if (err.response.data.message == "not found")
            this.errorAlrtText = "لاتمتلك الصلاحية الكافية";
          else if (err.response.data.message == "activeSubscription")
            this.errorAlrtText = "تم تفعيل العرض مسبقا";
          else if (err.response.data.message == "not eligible")
            this.errorAlrtText = err.response.data.aqsatiMessage;
          else if (err.response.data.message == "failed installment")
            this.errorAlrtText = err.response.data.aqsatiMessage;
          else if (err.response.data.message == "missingArguments")
            this.errorAlrtText = "الرجاء ملئ جميع الحقول";
          else if (err.response.data.errors.paymentMethod) {
            if (
              err.response.data.errors.paymentMethod[0] ==
              "The payment method field is required when status is closed."
            )
              this.errorAlrtText = "الرجاء اختيار طريقة الدفع";
            else if (
              err.response.data.errors.paymentMethod[0] ==
              "The given data was invalid."
            )
              this.errorAlrtText = "الرجاء اختيار طريقة الدفع";
            else if (
              err.response.data.errors.paymentMethod[0] ==
              "The selected payment method is invalid."
            )
              this.errorAlrtText = "الرجاء اختيار طريقة الدفع";
          } else if (err.response.data.errors.amount) {
            if (
              err.response.data.errors.amount[0] ==
              "The amount field is required when status is closed."
            )
              this.errorAlrtText = "حقل المبلغ مطلوب";
          } else if (err.response.data.errors.cardNumber) {
            if (err.response.data.errors.cardNumber[0] == "validation.luhn")
              this.errorAlrtText = "البطاقة التي ادخلتها غير صالحة";
          }
          //
          else this.errorAlrtText = "حدث خطاء, فشل في العملية";
          this.loadingSpinner = false;
          this.successAlrt = false;
          this.errorAlrt = true;
        });
    },
    closeDialog() {
      this.Info.paymentMethod = this.paymentMethod;
      this.Info.amount = null;
      this.Info.months = null;
      this.Info.patientType = null;
      this.Info.cardType = null;
      this.Info.cardNumber = null;
      this.Info.cardAccountNumber = null;
      this.Info.otp = null;
      this.Info.sessionId = null;
      this.successAlrt = false;
      this.errorAlrt = false;
      this.loadingSpinner = false;

      this.$emit("activateOfferSubscriptionDialogClose", false);
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },
  },
};
</script>

<style scoped lang="scss">
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>

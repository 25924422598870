<template>
  <v-dialog v-if="CommentDialog" :value="CommentDialog" width="500">
    <v-card v-click-outside="closeDialog">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">
          اضافة تشخيص للمريض
        </h5>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-alert dense outlined type="error" v-if="errorAlrt">
          حدث خطاء فشل في العملية
        </v-alert>
        <v-alert dense outlined type="success" v-if="successAlrt">
          عملية الاضافة تمت بنجاح
        </v-alert>
        <v-form ref="comment_form" lazy-validation>
          <v-textarea
            label="تشخيص الطبيب"
            :rules="requiredRule"
            v-model="doctorComment"
            rows="3"
          ></v-textarea>
        </v-form>
        <v-btn
          color="indigo white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="addComment"
        >
          حفظ
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog">
          اغلاق
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["CommentDialog", "id", "currentComment"],

  watch: {
    currentComment(newVal) {
      this.doctorComment = newVal;
    },
  },

  data() {
    return {
      doctorComment: this.currentComment,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      loadingSpinner: false,
      errorAlrt: false,
      successAlrt: false,
      Info: { comment: null, id: 0 },
    };
  },

  methods: {
    ...mapActions(["addDoctorComment"]),
    closeDialog() {
      this.$emit("commentDialogClose", false);
    },

    addComment() {
      console.log(this.doctorComment);
      if (!this.$refs.comment_form.validate()) return;
      this.Info.comment = this.doctorComment;
      this.Info.id = this.id;
      this.loadingSpinner = true;

      this.addDoctorComment(this.Info)
        .then(() => {
          this.loadingSpinner = false;
          this.successAlrt = true;
          setTimeout(() => {
            this.successAlrt = false;
            this.errorAlrt = false;
            this.closeDialog();
            //this.$emit("commentDialogClose", false);
          }, 3000);
        })
        .catch(() => {
          this.errorAlrt = true;
          this.loadingSpinner = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>

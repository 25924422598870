<template>
  <v-dialog :value="DeleteChronicBookDialog" persistent max-width="290">
    <v-card v-click-outside="handleClickOutSide" dir="rtl">
      <v-card-title class="headline"> تاكيد عملية الحذف </v-card-title>
      <v-card-text>
        <div class="mb-2">
          هل انت متاكد من انك تريد حذف الكرت
          <strong>({{ cardNumber }})</strong>
          ؟
        </div>

        <v-alert dense outlined :type="alrtType" v-if="alrtState">
          {{ alrtMsg }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :loading="deleteLoading"
          :disabled="deleteLoading"
          color="red darken-1 white--text"
          rounded
          @click="deleteFn"
        >
          حذف
        </v-btn>

        <v-btn color="dark darken-1" text @click="closeDialog"> اغلاق </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions } from "vuex";

  export default {
    name: "DeleteDoctor",
    props: ["DeleteChronicBookDialog", "cardNumber", "id"],
    data() {
      return {
        alrtMsg: null,
        alrtType: null,
        alrtState: false,
        deleteLoading: false,
      };
    },
    methods: {
      ...mapActions(["deleteNotebook"]),
      closeDialog() {
        this.$emit("deleteChronicBookDialogClosed", this.dialog);
      },
      handleClickOutSide(e) {
        if (e.target.className == "v-overlay__scrim") this.closeDialog();
      },
      deleteFn() {
        this.deleteLoading = true;
        this.deleteNotebook({ id: this.id, _method: "delete" })
          .then(() => {
            this.deleteLoading = false;
            this.alrtMsg = "عملية الحذف تمت بنجاح";
            this.alrtType = "success";
            this.alrtState = true;
            setTimeout(() => {
              this.alrtState = false;
              this.closeDialog();
            }, 2500);
          })
          .catch(() => {
            this.deleteLoading = false;
            this.alrtMsg = "حدث خطاء الرجاء المحاولة لاحقاّ!";
            this.alrtType = "error";
            this.alrtState = true;
          });
      },
    },
  };
</script>

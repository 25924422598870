import axios from "@/axios";
const state = {
  advices: {},
  categories: []
};

const getters = {
  allAdvices: state => state.advices.data,
  getMedicalAdvice: (state) => (id) => {
    var advice = state.advices.data.find((advice) => advice.id === id);
    return advice;
  }, 
  allAdvicesCategories: state => state.categories,
  getAdvicesCurrentPage: state => state.advices.current_page,
  getAdvicesLastPage: state => state.advices.last_page
};
const actions = {
  async fetchAdviceCategories({ commit }) {
    const response = await axios.get("/clinic/adviceCategories");
    commit("SET_ADVICES_CATS", response.data);
  },
  async updateMedicalAdviceData({ commit }, advice) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post("/clinic/medicalAdvices/" + advice.id, advice);
   
    if (response.data.success == "true")
      commit("UPDATE_MEDICAL_ADVICE", response.data.data);
    return response.data.message;
  },
  async fetchAdvices({ commit }, info) {
    const response = await axios.get(
      "/clinic/medicalAdvices?per_page=10&&page=" +
        info.page +
        "&&search=" +
        info.keyword
    );
    commit("SET_ADVICES", response.data);
  },
  async addMedicalAdvice({ commit }, info) {
    const response = await axios.post("/clinic/medicalAdvices", info);
    if (response.status == 200) {
      commit("ADD_MEDICAL_ADVICE", response.data);
      return response.data;
    }
  },
  /*  async deleteAdvice({ commit }, id) {
    const response = await axios.post("/clinic/medicalAdvices/" + id,{"_method":"delete"});
    if (response.data.message == "deleted") {
      commit("DELETE_ADVICE", id);
      return "deleted";
    } else return "failed";
  }, */
  setAdvicesCurrentPage({ commit }, currentPage) {
    commit("SET_ADVICES_CURRENT_PAGE", currentPage);
  },
 
};
const mutations = {
  SET_ADVICES: (state, data) => (state.advices = data),
  SET_ADVICES_CURRENT_PAGE: (state, data) =>
    (state.advices.current_page = data),
  SET_ADVICES_CATS: (state, data) => (state.categories = data),
  ADD_MEDICAL_ADVICE: (state, data) => {
    state.advices.data.unshift(data);
  },
  UPDATE_MEDICAL_ADVICE:(state, data) => {
    const i = state.advices.data.map(item => item.id).indexOf(data.id);
    state.advices.data[i].title=data.title;
    state.advices.data[i].body=data.body;
    state.advices.data[i].category_info.name=data.category_info.name;
  },
  /*   DELETE_ADVICE: (state, id) => {
    const i = state.advices.data.map(item => item.id).indexOf(id);
    state.advices.data.splice(i, 1);
  } */
};

export default {
  state,
  getters,
  actions,
  mutations
};

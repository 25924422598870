<template>
  <v-dialog
    :value="PatientBiometricsDialog"
    v-if="PatientBiometricsDialog"
    width="550"
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="mx-auto pr-1">القياسات الحيوية</h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-container fluid>
          <v-row class="text-right px-1">
            <v-col cols="12" class="text-right">
              <v-avatar
                size="56"
                v-if="patientData.profile_picture != 'default'"
              >
                <v-img
                  max-height="150"
                  max-width="150"
                  :src="patientData.profile_picture"
                ></v-img>
              </v-avatar>

              <v-avatar size="56" v-else color="indigo">
                <v-icon v-if="patientData.gender == 'male'" dark color="white"
                  >mdi-human-male</v-icon
                >
                <v-icon v-else dark color="white">mdi-human-female</v-icon>
              </v-avatar>
              <span class="mr-2" style="fontsize: 16px">{{
                patientData.fullname
              }}</span>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <v-form ref="patient_biometrics_form">
            <v-row class="align-center">
              <v-col cols="6" md="8">
                <v-text-field
                  v-model="biometricData.bloodPressureSys"
                  label="SYS"
                  suffix="MMHG"
                ></v-text-field>
                <v-text-field
                  v-model="biometricData.bloodPressureDia"
                  label="DIA"
                  suffix="MMHG"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-subheader>Blood pressure</v-subheader>
              </v-col>
            </v-row>

            <v-row class="align-center">
              <v-col cols="6" md="8">
                <v-text-field
                  v-model="biometricData.pulseRate"
                  label="rate"
                  suffix="B/M"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-subheader>Pulse rate</v-subheader>
              </v-col>
            </v-row>

            <v-row class="align-center">
              <v-col cols="6" md="8">
                <v-text-field
                  v-model="biometricData.bodyTemprature"
                  label="temp"
                  suffix="C"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-subheader>Body temprature</v-subheader>
              </v-col>
            </v-row>

            <v-row class="align-center">
              <v-col cols="6" md="8">
                <v-text-field
                  v-model="biometricData.spo2"
                  label="percent"
                  suffix="%"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-subheader>Spo2</v-subheader>
              </v-col>
            </v-row>

            <v-row class="align-center">
              <v-col cols="6" md="8">
                <v-text-field
                  v-model="biometricData.weight"
                  label="kilo"
                  suffix="KG"
                  @keyup="calcBmi"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-subheader>Weight</v-subheader>
              </v-col>
            </v-row>

            <v-row class="align-center">
              <v-col cols="6" md="8">
                <v-text-field
                  v-model="biometricData.height"
                  label="centimeter"
                  suffix="CM"
                  @keyup="calcBmi"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-subheader>Height</v-subheader>
              </v-col>
            </v-row>

            <v-row class="align-center">
              <v-col cols="6" md="8">
                <v-text-field
                  v-model="bmi"
                  label="mass"
                  suffix="Kg/m2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-subheader>Body mass index</v-subheader>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col cols="6" md="8">
                <v-text-field v-model="biometricData.rbs"></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-subheader>RBS</v-subheader>
              </v-col>
            </v-row>
            <v-row class="align-center">
              <v-col cols="6" md="8">
                <v-text-field v-model="biometricData.notes"></v-text-field>
              </v-col>
              <v-col cols="6" md="4">
                <v-subheader>Notes</v-subheader>
              </v-col>
            </v-row>
            <v-btn
              :loading="loadingSpinner"
              :disabled="loadingSpinner"
              @click="addNewBiometrics"
              color="primary"
              block
              >حفظ</v-btn
            >
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-snackbar timeout="2500" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  watch: {
    biometricData(newValue) {
      if (newValue.bodyMass) this.bmi = newValue.bodyMass;
    },
  },
  props: [
    "PatientBiometricsDialog",
    "reservationId",
    "patientData",
    "biometricData",
  ],
  data() {
    return {
      bmi: this.biometricData.bodyMass,
      snackbar: false,
      snackbarText: "",
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      loadingSpinner: false,
      errorAlrt: false,
      successAlrt: false,
      Info: { id: 0, biometrics: null },
      Biometric: {
        bloodPressureSys: "",
        bloodPressureDia: "",
        pulseRate: "",
        bodyTemprature: "",
        spo2: "",
        weight: "",
        height: "",
        bodyMass: "",
        rbs: "",
      },
    };
  },

  methods: {
    ...mapActions(["addBiometrics"]),
    calcBmi() {
      if (this.biometricData.height && this.biometricData.weight) {
        var weight = parseInt(this.biometricData.weight);
        var height = parseInt(this.biometricData.height) / 100;

        this.bmi = weight / (height * height);
        this.bmi = parseFloat(this.bmi).toFixed(2);
      }
    },
    addNewBiometrics() {
      if (!this.$refs.patient_biometrics_form.validate()) return;
      this.Biometric.bloodPressureSys = this.biometricData.bloodPressureSys;
      this.Biometric.bloodPressureDia = this.biometricData.bloodPressureDia;
      this.Biometric.pulseRate = this.biometricData.pulseRate;
      this.Biometric.bodyTemprature = this.biometricData.bodyTemprature;
      this.Biometric.spo2 = this.biometricData.spo2;
      this.Biometric.weight = this.biometricData.weight;
      this.Biometric.height = this.biometricData.height;
      this.Biometric.bodyMass = this.bmi;
      this.Biometric.rbs = this.biometricData.rbs;
      this.Biometric.notes = this.biometricData.notes;

      this.loadingSpinner = true;
      this.Info.id = this.reservationId;
      this.Info.biometrics = JSON.stringify(this.Biometric);

      this.addBiometrics(this.Info)
        .then((res) => {
          this.loadingSpinner = false;

          if (res == "success") {
            this.snackbar = true;
            this.snackbarText = "تم الحفظ";
          } else {
            this.snackbar = true;
            this.snackbarText = "حدث خطاء ";
          }
        })
        .catch(() => {
          this.snackbar = true;
          this.snackbarText = "حدث خطاء ";
          this.loadingSpinner = false;
        });
    },

    closeDialog() {
      this.bmi = "";
      this.snackbar = false;
      this.snackbarText = "";
      this.$emit("patientBiometricsDialogClose", false);
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },
  },
  /*   computed: {
    ...mapGetters(["getPatientReservation"]),
    biometricsData() {
      return this.getPatientReservation(this.reservationId).biometrics;
    },
  },
  created() {
    if (this.PatientBiometricsDialog) {
      console.log("mounted");
      this.fetchBiometricsOfReservation(this.reservationId).then(() => {
        var bio = this.getPatientReservation(this.reservationId).biometrics;
        if (bio != null) {
          this.Biometric.bloodPressureSys = bio.bloodPressureSys;
          this.Biometric.bloodPressureDia = bio.bloodPressureDia;
          this.Biometric.pulseRate = bio.pulseRate;
          this.Biometric.bodyTemprature = bio.bodyTemprature;
          this.Biometric.spo2 = bio.spo2;
          this.Biometric.weight = bio.weight;
          this.Biometric.height = bio.height;
          this.Biometric.bodyMass = bio.bodyMass;
          this.Biometric.rbs = bio.rbs;
        }
      });
    }
  }, */
};
</script>

<style scoped lang="scss">
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>

<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-dialog
    :value="AnalysisDialog"
    v-if="AnalysisDialog"
    :width="$vuetify.breakpoint.smAndDown ? '90%' : '75%'"
    persistent
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">الفحوصات الطبية</h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <div class="text-right px-2">
          <h6>اسم المريض</h6>
          <h3>{{ patientData.fullname }}</h3>
          <h6>فصيلة الدم</h6>
          <h3>{{ patientData.blood_type }}</h3>
          <h6>الحساسية للادوية</h6>
          <h3>
            {{
              patientData.drug_allergy == null
                ? "لايوجد"
                : patientData.drug_allergy
            }}
          </h3>
          <h6>لقاح كورونا</h6>
          <h3>{{ patientData.corona_vaccine }}</h3>
        </div>
        <v-divider
          v-if="getRole != 'clinic_assistant'"
          class="my-3"
        ></v-divider>
        <v-container>
          <v-form
            v-if="getRole != 'clinic_assistant'"
            class="row"
            ref="analysis_form"
            lazy-validation
          >
            <v-col cols="12" md="6">
              <v-select
                :items="categories"
                v-model="Analysis.category"
                label="نوع الخدمة"
                outlined
                item-text="title"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="selectedTestNames"
                :items="filteredTestNames"
                label="اسم الفحص"
                placeholder="بحث عن الاسم"
                item-text="name"
                item-value="id"
                required
                outlined
                multiple
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                label="ملاحظات الطبيب"
                placeholder="الملاحظات"
                v-model="Analysis.doctor_notes"
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn
                @click="addAnalysisToList"
                large
                color="green lighten-1 white--text"
              >
                <v-icon dark> mdi-plus </v-icon>
                1 اضافة
              </v-btn>
            </v-col>
          </v-form>
          <v-subheader
            class="fa20 black--text d-flex flex-row justify-space-between"
            style="font-size: 18px"
          >
            <div>الفحوصات</div>
            <div v-if="getRole == 'clinic_assistant'">
              التكلفة الإجمالية:<strong>{{ totalAnalysisCost }}</strong>
            </div>
          </v-subheader>
          <div
            v-for="(analysis, index) in updatedAnalyses"
            v-if="!analysis.delete"
            :key="analysis.index"
            class="ma-2 pa-2 d-block text-right"
            style="
              position: relative;
              border: 1px solid #2196f3;
              border-radius: 5px;
              color: #2196f3;
              font-size: 15px;
            "
          >
            <div class="d-flex flex-row justify-space-between">
              <div class="d-flex flex-column">
                <div>
                  {{ getTestNameById(analysis.analysis_type_id) }}
                </div>
                <div class="mx-0" v-if="analysis.doctor_notes">
                  <span>ملاحظات الطبيب:</span>
                  <span class="mr-1">{{ analysis.doctor_notes }}</span>
                </div>
                <div class="mx-0" v-if="getRole == 'clinic_assistant'">
                  <span> السعر:</span>
                  <strong class="mr-1">{{ analysis.cost }}</strong>
                </div>
                <div class="">
                  <small>{{
                    analysis.created_at
                      ? analysis.created_at.substring(0, 10)
                      : new Date().toISOString().substr(0, 10)
                  }}</small>
                </div>
              </div>
              <div v-if="analysis.files" class="d-flex flex-row align-center">
                <div
                  v-for="file in analysis.files"
                  class="mx-2 cursor-pointer"
                  style="cursor: pointer"
                >
                  <v-img
                    style="border: 1px solid #000"
                    width="60"
                    height="60"
                    :src="file"
                    @click="openImageInNewWindow(file)"
                  ></v-img>
                </div>
              </div>
              <div class="d-flex flex-row align-center">
                <div>
                  <v-checkbox
                    v-model="analysis.is_paid"
                    v-if="getRole == 'clinic_assistant'"
                    class="d-inline-block"
                    @change="changePaidStatusFn(analysis)"
                  >
                  </v-checkbox>
                </div>
                <div>
                  <v-icon
                    v-if="getRole != 'clinic_assistant'"
                    @click="removeAnalysisFromList(index, analysis)"
                    color="red"
                    class="my-auto"
                    >mdi-close-box</v-icon
                  >
                </div>
              </div>
            </div>
          </div>
          <v-alert dense outlined type="error" v-if="errorAlrt">
            حدث خطاء فشل في العملية
          </v-alert>
          <v-alert dense outlined type="success" v-if="successAlrt">
            العملية تمت بنجاح
          </v-alert>
          <v-divider></v-divider>
        </v-container>
        <v-btn
          v-if="getRole != 'clinic_assistant'"
          class="ml-1 mb-2 mb-md-0"
          color="blue  white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="addAnalysisFn"
          block
          large
        >
          2 حفظ
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
          <v-icon right>mdi-content-save-outline</v-icon>
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbarState" :color="snackbarType">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbarState = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    props: [
      "AnalysisDialog",
      "id",
      "currentAnalyses",
      "patientData",
      "clinicData",
      "doctorData",
    ],
    data() {
      return {
        updatedAnalyses: Object.assign({}, this.currentAnalyses),
        snackbarState: false,
        snackbarText: "",
        snackbarType: "",

        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        loadingSpinner: false,
        errorAlrt: false,
        successAlrt: false,
        selectedTestNames: [],
        Info: {
          analyses: [],
          patient_id: null,
          clinic_id: null,
          reservation_id: null,
        },
        Analysis: {
          category: null,
          doctor_notes: null,
        },
        categories: [
          { title: "مختبر", value: "analysis" },
          { title: "اشعة", value: "x-ray" },
          { title: "سونار", value: "sonar" },
          { title: "ايكو", value: "eco" },
          { title: "تخطيط قلب", value: "ecg" },
          { title: "نيوبيلايزر", value: "nebulizer" },
          { title: "ضماد", value: "bandage" },
          { title: "اخرى", value: "others" },
        ],
      };
    },
    created() {
      this.fetchAnalysisTypes();
    },
    watch: {
      currentAnalyses(new_value) {
        this.updatedAnalyses = JSON.parse(JSON.stringify(new_value));
      },
    },
    computed: {
      ...mapGetters(["getAnalysisTypes", "getRole"]),
      totalAnalysisCost() {
        return this.updatedAnalyses.reduce((total, analysis) => {
          // Assuming 'cost' is the property representing the cost in each analysis
          return total + parseFloat(analysis.cost || 0);
        }, 0);
      },
      filteredTestNames() {
        if (this.Analysis.category) {
          return this.getAnalysisTypes.filter(
            (test) => test.category === this.Analysis.category
          );
        } else {
          // If no category is selected, show all test names
          return this.testNames;
        }
      },
    },

    methods: {
      ...mapActions([
        "addAnalysis",
        "fetchAnalysisTypes",
        "changeAnalysisPaidStatus",
      ]),
      openImageInNewWindow(imageUrl) {
        window.open(imageUrl, "_blank");
      },
      handleClickOutSide(e) {
        if (e.target.className == "v-overlay__scrim") this.closeDialog();
      },

      getTestNameById(id) {
        const test = this.getAnalysisTypes.find((test) => test.id === id);
        return test ? test.name : ""; // Assuming 'name' is the property you want to display
      },
      closeDialog() {
        this.$emit("analysisDialogClose", false);
      },
      changePaidStatusFn(analysis) {
        if (analysis && analysis.id) {
          this.changeAnalysisPaidStatus(analysis)
            .then(() => {
              this.snackbarType = "green";
              this.snackbarText = "العملية تمت بنجاح";
              this.snackbarState = true;
            })
            .catch((err) => {
              console.log("err", err);
              this.snackbarType = "red";
              this.snackbarText = "حدث خطاء حاول مرة اخرى";
              this.snackbarState = true;
            });
        }
      },

      addAnalysisToList() {
        if (!this.Analysis.category || this.selectedTestNames.length === 0) {
          this.snackbarType = "red";
          this.snackbarText = "الرجاء ملئ جميع الحقول";
          this.snackbarState = true;

          return;
        }

        this.selectedTestNames.forEach((selectedTest) => {
          var analysis = {
            category: this.Analysis.category,
            analysis_type_id: selectedTest,
            doctor_notes: this.Analysis.doctor_notes,
          };

          this.updatedAnalyses.push(analysis);
        });

        this.Analysis.category = null;
        this.selectedTestNames = [];
        this.Analysis.doctor_notes = null;
      },
      removeAnalysisFromList(i, analysis) {
        if (analysis && analysis.id) {
          const index = this.updatedAnalyses.findIndex(
            (item) => item.id === analysis.id
          );

          if (index !== -1) {
            this.$set(this.updatedAnalyses, index, {
              ...analysis,
              delete: true,
            });
          }
        } else this.updatedAnalyses.splice(i, 1);
      },
      addAnalysisFn() {
        this.Info.analyses = JSON.stringify(this.updatedAnalyses);
        this.Info.patient_id = this.patientData.id;
        this.Info.clinic_id = this.clinicData.id;
        this.Info.reservation_id = this.id;
        this.loadingSpinner = true;

        this.addAnalysis(this.Info)
          .then(() => {
            this.loadingSpinner = false;
            this.successAlrt = true;
            setTimeout(() => {
              this.successAlrt = false;
              this.errorAlrt = false;
              this.$refs.analysis_form.reset();
            }, 1000);
          })
          .catch(() => {
            this.errorAlrt = true;
            this.loadingSpinner = false;
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  .remove-analysis {
    position: absolute;
    left: 0.5rem;
    top: 25%;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
</style>

<template>
  <v-dialog
    :value="PatientProfileDialog"
    v-if="PatientProfileDialog"
    :width="$vuetify.breakpoint.smAndDown ? '90%' : '90%'"
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="mx-auto pr-1">معلومات المراجع</h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-container fluid>
          <v-row class="text-right px-1">
            <v-col class="order-1 order-md-2" cols="12" md="6">
              <div class="d-flex">
              <v-avatar
                size="56"
                v-if="patientData.profile_picture != 'default'"
              >
                <v-img
                  max-height="150"
                  max-width="150"
                  :src="patientData.profile_picture"
                ></v-img>
              </v-avatar>

              <v-avatar size="56" v-else color="indigo">
                <v-icon v-if="patientData.gender == 'male'" dark color="white"
                  >mdi-human-male</v-icon
                >
                <v-icon v-else dark color="white">mdi-human-female</v-icon>
              </v-avatar>
            </div>
              <v-file-input @change="updateProfilePictureFn" class="d-flex " v-model="newProfilePicture" label="تحديث الصورة الشخصية" accept="image/*"></v-file-input>

              <h6>الاسم</h6>
              <h3>{{ patientData.fullname }}</h3>

              <h6>تاريخ الميلاد</h6>
              <h3>{{ patientData.date_of_birth }}</h3>
              <h6>الجنس</h6>
              <h3>{{ patientData.gender == "male" ? "ذكر" : "انثى" }}</h3>
              <h6>رقم الهاتف</h6>
              <h3 @click="copyMobile(patientData.mobile)">
                {{ patientData.mobile ? patientData.mobile : "لايوجد" }}
              </h3>
              <h6>العنوان</h6>
              <h3>{{ patientData.address }}</h3>
              <h6>المدينة</h6>
              <h3>{{ patientData.city }}</h3>
              <h6>المهنة</h6>
              <h3>
                {{
                  patientData.profession == null
                    ? "لايوجد"
                    : patientData.profession
                }}
              </h3>

              <h6>فصيلة الدم</h6>
              <h3>{{ patientData.blood_type }}</h3>
              <h6 class="red--text">الحساسية للادوية</h6>
              <h3>
                {{
                  patientData.drug_allergy == null
                    ? "لايوجد"
                    : patientData.drug_allergy
                }}
              </h3>
              <h6>لقاح كورونا</h6>
              <h3>{{ patientData.corona_vaccine }}</h3>
              <h4 class="my-2">اضافة وصف لحالة المراجع</h4>
              <v-form ref="doctor_note_form" lazy-validation>
                <v-textarea
                  v-model="doctorNote"
                  append-outer-icon="mdi-content-save"
                  filled
                  clear-icon="mdi-close-circle"
                  rows="1"
                  label="وصف  يراه الطبيب فقط"
                  type="text"
                  @click:append-outer="setDoctorNote"
                  :rules="requiredRule"
                ></v-textarea>
              </v-form>
              <h3 class="mb-2 red--text">القياسات الحيوية</h3>
              <v-divider class="mb-3"></v-divider>

              <div style="max-height: 260px; overflow-y: auto; padding: 15px">
                <div
                  class="text-left"
                  v-for="(bio, index) in getPatientBiometricsData"
                  :key="index"
                >
                  <div v-if="bio.biometrics != null">
                    <h3 class="mb-2">{{ bio.created_at.substring(0, 10) }}</h3>
                    <h4 v-if="bio.biometrics.bloodPressureSys">
                      Blood pressure SYS: {{ bio.biometrics.bloodPressureSys }}
                    </h4>
                    <h4 v-if="bio.biometrics.bloodPressureDia">
                      Blood pressure DIA: {{ bio.biometrics.bloodPressureDia }}
                    </h4>
                    <h4 v-if="bio.biometrics.pulseRate">
                      Pulse Rate: {{ bio.biometrics.pulseRate }}
                    </h4>
                    <h4 v-if="bio.biometrics.bodyTemprature">
                      body Temprature: {{ bio.biometrics.bodyTemprature }}
                    </h4>
                    <h4 v-if="bio.biometrics.spo2">
                      spo2: {{ bio.biometrics.spo2 }}
                    </h4>
                    <h4 v-if="bio.biometrics.weight">
                      weight: {{ bio.biometrics.weight }}
                    </h4>
                    <h4 v-if="bio.biometrics.height">
                      height: {{ bio.biometrics.height }}
                    </h4>
                    <h4 v-if="bio.biometrics.bodyMass">
                      body Mass: {{ bio.biometrics.bodyMass }}
                    </h4>
                    <h4 v-if="bio.biometrics.rbs">
                      rbs: {{ bio.biometrics.rbs }}
                    </h4>
                    <h4 v-if="bio.biometrics.notes" dir="ltr">
                      <span>notes:</span> {{ bio.biometrics.notes }}
                    </h4>
                    <v-divider class="grey lighten-4"></v-divider>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col class="order-2 order-md-1" cols="12" md="6">
              <h3 class="mb-2">السجل المرضي</h3>
              <v-divider class="mb-3"></v-divider>

              <v-form ref="patient_medical_history">
                <v-select
                  :items="chronicDiseases"
                  v-model="patientData.medical_history.chronicDiseases"
                  label="Chronic Diseases"
                  multiple
                  chips
                  hint="multiple chose"
                  persistent-hint
                  required
                  outlined
                >
                </v-select>
                <v-text-field
                  v-if="checkChronicDiseases() == true"
                  v-model="patientData.medical_history.otherChronicDiseases"
                  label="Other Chronic Diseases"
                  type="text"
                  outlined
                ></v-text-field>
                <v-select
                  :items="choseOptions"
                  v-model="patientData.medical_history.smokingStatus"
                  label="smoking Status"
                  chips
                  outlined
                >
                </v-select>
                <v-select
                  :items="choseOptions"
                  v-model="patientData.medical_history.drinkingAlcohol"
                  label="drinking Alcohol"
                  chips
                  outlined
                >
                </v-select>
                <v-textarea
                  v-model="patientData.medical_history.pastSurgery"
                  clear-icon="mdi-close-circle"
                  rows="1"
                  label="Past Surgery"
                  type="text"
                  outlined
                ></v-textarea>
                <v-textarea
                  v-model="patientData.medical_history.familyHistory"
                  clear-icon="mdi-close-circle"
                  rows="1"
                  label="Family history"
                  type="text"
                  outlined
                ></v-textarea>
                <v-textarea
                  v-model="patientData.medical_history.drugsAllergy"
                  clear-icon="mdi-close-circle"
                  rows="1"
                  label="Drugs Allergy"
                  type="text"
                  outlined
                ></v-textarea>
                <v-textarea
                  v-model="patientData.medical_history.chronicUseOfDrugs"
                  clear-icon="mdi-close-circle"
                  rows="1"
                  label="Chronic use Of Drugs"
                  type="text"
                  outlined
                ></v-textarea>
                <v-btn
                  :loading="loadingSpinner"
                  :disabled="loadingSpinner"
                  @click="addMedicalHistoryToPatient"
                  color="primary"
                  block
                  >حفظ</v-btn
                >
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-snackbar timeout="2500" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    props: ["PatientProfileDialog", "patientData"],

    computed: {
      ...mapGetters(["getPatientBiometricsData"]),
    },
    data() {
      return {
        snackbar: false,
        snackbarText: "",
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        loadingSpinner: false,
        errorAlrt: false,
        successAlrt: false,
        doctorNote: this.patientData ? this.patientData.doctor_note : '', 
        Info: { id: 0, doctorNote: null },
        Info2: { id: 0, medicalHistory: null },
        PatientMedicalHistory: {
          chronicDiseases: [],
          otherChronicDiseases: "",
          pastSurgery: "",
          familyHistory: "",
          drugsAllergy: "",
          chronicUseOfDrugs: "",
          smokingStatus: "",
          drinkingAlcohol: "",
        },

        choseOptions: ["yes", "no"],
        chronicDiseases: [
          "No Diseases",
          "Hypertension",
          "Diabetes",
          "Asthma",
          "Epilepsy",
          "Arthritis",
          "Malignancy",
          "Hepatitis",
          "Dyslipidemia",
          "Chronic kidney dis",
          "IHD",
          "Other",
        ],
        newProfilePicture: null, 
        formData :new FormData(),

      };
    },

    watch: {
      patientData(newVal) {
        this.doctorNote = newVal.doctor_note;
      },
      PatientProfileDialog(newVal) {
        if (newVal == true) this.fetchPatientBiometrics(this.patientData.id);
      },
    },

    methods: {
      ...mapActions([
        "addDoctorNote",
        "addPatientMedicalHistory",
        "fetchPatientBiometrics",
        "updatePatientProfilePicture",
      ]),
      
      checkChronicDiseases() {
        if (
          this.patientData.medical_history.chronicDiseases &&
          this.patientData.medical_history.chronicDiseases.includes("Other")
        )
          return true;
        else {
          this.patientData.medical_history.otherChronicDiseases = "";
          return false;
        }
      },

      addMedicalHistoryToPatient() {
        this.PatientMedicalHistory.chronicDiseases =
          this.patientData.medical_history.chronicDiseases;
        if (
          this.patientData.medical_history.chronicDiseases &&
          this.patientData.medical_history.chronicDiseases.includes("Other")
        ) {
          this.PatientMedicalHistory.otherChronicDiseases =
            this.patientData.medical_history.otherChronicDiseases;
        }
        this.PatientMedicalHistory.pastSurgery =
          this.patientData.medical_history.pastSurgery;
        this.PatientMedicalHistory.familyHistory =
          this.patientData.medical_history.familyHistory;
        this.PatientMedicalHistory.drugsAllergy =
          this.patientData.medical_history.drugsAllergy;
        this.PatientMedicalHistory.chronicUseOfDrugs =
          this.patientData.medical_history.chronicUseOfDrugs;
        this.PatientMedicalHistory.smokingStatus =
          this.patientData.medical_history.smokingStatus;
        this.PatientMedicalHistory.drinkingAlcohol =
          this.patientData.medical_history.drinkingAlcohol;

        this.loadingSpinner = true;
        this.Info2.id = this.patientData.id;
        this.Info2.medicalHistory = JSON.stringify(this.PatientMedicalHistory);

        this.addPatientMedicalHistory(this.Info2)
          .then((res) => {
            this.loadingSpinner = false;

            if (res == "success") {
              this.snackbar = true;
              this.snackbarText = "تم الحفظ";
            } else {
              this.snackbar = true;
              this.snackbarText = "حدث خطاء ";
            }
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = "حدث خطاء ";
            this.loadingSpinner = false;
          });
      },
      setDoctorNote() {
        if (!this.$refs.doctor_note_form.validate()) return;

        this.Info.doctorNote = this.doctorNote;
        this.Info.id = this.patientData.id;

        this.addDoctorNote(this.Info)
          .then((res) => {
            if (res == "success") {
              this.snackbar = true;
              this.snackbarText = "تم الحفظ";
            } else {
              this.snackbar = true;
              this.snackbarText = "حدث خطاء ";
            }
          })
          .catch(() => {
            this.snackbar = true;
            this.snackbarText = "حدث خطاء ";
          });
      },
      copyMobile(mob) {
        navigator.clipboard.writeText("0" + mob.substring(3, mob.length));
        this.snackbarText = "تم نسخ الرقم";
        this.snackbar = true;
      },
      closeDialog() {
        this.snackbar = false;
        this.snackbarText = "";
        this.$emit("patientProfileDialogClose", false);
      },
      handleClickOutSide(e) {
        if (e.target.className == "v-overlay__scrim") this.closeDialog();
      },
      updateProfilePictureFn() {
    this.formData=new FormData();
    if (!this.newProfilePicture) {
      this.snackbarText = "يرجى اختيار صورة";
      this.snackbar = true;
      return;
    }
const currentRoute = this.$route.path;
   
    this.formData.append('profile_picture', this.newProfilePicture);
    this.formData.append('_method',"put");
    this.formData.append('id', this.patientData.id);
    this.formData.append('route',currentRoute);

    this.updatePatientProfilePicture(this.formData)
          .then((res) => {
            if (res == "success") {
        this.snackbarText = "تم تحديث الصورة الشخصية بنجاح";
        this.snackbar = true;
        this.newProfilePicture = null; // Reset the new profile picture variable
            } else {
              this.snackbar = true;
              this.snackbarText = "حدث خطاء ";
            }
          })
          .catch(error => {
            this.snackbarText = "حدث خطأ أثناء تحديث الصورة الشخصية";
        this.snackbar = true;
        console.error("Error updating profile picture:", error);
          });
  },
}
 
  };
</script>

<style scoped lang="scss">
  h6 {
    color: #464646;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
</style>

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./scss/main.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUserDoctor } from "@fortawesome/free-solid-svg-icons";
library.add(faUserDoctor);
Vue.component("font-awesome-icon", FontAwesomeIcon);
require("@/store/modules/subscriber");
Vue.config.productionTip = false;

import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.Pusher = Pusher;
var key = process.env.VUE_APP_PUSHER_APP_KEY;
window.Echo = new Echo({
  broadcaster: "pusher",
  key: key,
  cluster: "eu",
  encrypted: true,
});

/* import Echo from "laravel-echo";
import Pusher from "pusher-js";
window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: "pusher",
  key: "3b71b52b985183093553",
  cluster: "eu",
  encrypted: true,
}); */

/* window.Pusher = require("pusher-js");
window.Echo = new Echo({
  broadcaster: "pusher",
  key: "myKey",
  wsHost: "develop.tabibtimes.com",
  wsPort:6001,
  wssPort:6001,
  disableStats: true,
 enabledTransports: ['ws', 'wss'],

}); */
//  forceTLS: false,
//disableStats: true,
store.dispatch("attempt", localStorage.getItem("user_token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});

<template>
  <v-dialog
    :value="AddPatientChronicDrugsDialog"
    v-if="AddPatientChronicDrugsDialog"
    :width="$vuetify.breakpoint.smAndDown ? '95%' : '90%'"
    persistent
  >
    <v-card>
      <v-card-title class="headline mb-3">
        <v-icon @click="closeDialog" color="black" size="30">mdi-close</v-icon>

        <h5 class="mx-auto pr-1">بطاقة الامراض المزمنة</h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-container fluid>
          <v-form ref="new_patient_form" lazy-validation>
            <v-row class="text-right">
              <v-col cols="10">
                <v-file-input
                  label="صورة المراجع"
                  v-model="Info.profilePicture"
                  accept="image/*"
                >
                </v-file-input>
              </v-col>
              <v-col cols="2">
                <v-avatar>
                  <img :src="profilePicture" />
                </v-avatar>
              </v-col>
            </v-row>
            <v-row class="text-right">
              <v-col cols="12" md="2">
                <v-text-field
                  outlined
                  v-model="Info.number"
                  placeholder="رقم الكرت"
                  label="رقم الكرت"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-combobox
                  @keyup="searchForPatients"
                  :search-input.sync="patientNameForSearch"
                  label="الاسم الثلاثي"
                  outlined
                  small-chips
                  :rules="requiredRule"
                  v-model="patientDataFromSearch"
                  :items="getPatientsList"
                  :item-text="text"
                  clearable
                >
                </v-combobox>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  v-model="mobile"
                  placeholder="رقم الهاتف"
                  label="رقم الهاتف"
                  :rules="mobileRule"
                  maxlength="10"
                  minlength="10"
                  suffix="964+"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" v-if="isGovClinic">
                <v-text-field
                  clearable
                  v-model="Info.nationalId"
                  label="رقم الهوية الوطنية"
                  outlined
                  type="text"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  clearable
                  v-model="Info.dob"
                  label="تاريخ الميلاد"
                  outlined
                  type="date"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="Info.gender"
                  :items="genders"
                  item-text="val"
                  item-value="id"
                  label="الجنس"
                  placeholder="اختر النوع"
                  required
                  :rules="requiredRule"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" md="2">
                <v-select
                  v-model="Info.bloodType"
                  :items="bloodTypes"
                  item-text="val"
                  item-value="id"
                  label="فصيلة الدم"
                  placeholder="اختر فصيلة الدم"
                  required
                  :rules="requiredRule"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" md="2">
                <v-select
                  v-model="Info.city"
                  :items="governorates"
                  item-text="val"
                  item-value="id"
                  label="المحافظة"
                  placeholder="اختر المحافظة"
                  required
                  :rules="requiredRule"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  v-model="Info.address"
                  placeholder="العنوان"
                  label="عنوان المراجع"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  v-model="Info.profession"
                  placeholder="المهنة"
                  label="مهنة المراجع"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  v-model="Info.drugAllergy"
                  placeholder="الحساسية للادوية"
                  label="الحساسية للادوية"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  clearable
                  v-model="Info.issueDate"
                  label="تاريخ الاصدار"
                  outlined
                  type="date"
                  :rules="requiredRule"
                  ref="issueDateRef"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  clearable
                  v-model="Info.expDate"
                  label="تاريخ الانتهاء"
                  outlined
                  type="date"
                  :rules="requiredRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="grey lighten-5 rounded">
              <v-col cols="12" md="4">
                <v-autocomplete
                  clearable
                  @input="updatePrescriptionFields"
                  v-model="drugId"
                  :search-input.sync="searchMedicine"
                  :items="getMedicines('chronic')"
                  label="اسم العلاج"
                  placeholder="بحث عن الاسم الطبي"
                  required
                  append-outer-icon="mdi-refresh"
                  @click:append-outer="fetchClinicMedFn"
                  outlined
                  item-text="drugNameIndexed"
                  item-value="id"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  label="الجرعة"
                  placeholder="example:500mg"
                  outlined
                  readonly
                  v-model="dose"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  :items="drugRoutes"
                  v-model="route"
                  label="الشكل الدوائي"
                  placeholder="Route"
                  outlined
                  readonly
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  v-model="quantity"
                  label="الكمية"
                  required
                  dir="ltr"
                  outlined
                  ref="repeating_ref"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" class="text-right">
                <v-btn
                  @click="addMedicineToList"
                  large
                  color="green lighten-1 white--text"
                >
                  <v-icon dark> mdi-plus </v-icon>
                  1 اضافة
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-subheader>الادوية</v-subheader>

                <div
                  v-for="(medicine, index) in selectedMedicines"
                  :key="medicine.index"
                  class="ma-2 pa-2"
                  style="
                    position: relative;
                    border: 1px solid #2196f3;
                    border-radius: 5px;
                    color: #2196f3;
                    font-size: 15px;
                  "
                >
                  <div>{{ medicine.drug }}</div>
                  <div>الكمية:{{ medicine.quantity }}</div>
                  <v-icon
                    @click="removePrescreptionFromList(index)"
                    color="red"
                    class="remove-prescription"
                    >mdi-close-box</v-icon
                  >
                </div>
              </v-col>
            </v-row>
          </v-form>

          <v-alert :type="alrtType" v-if="alrtState">
            {{ alrtMsg }}
          </v-alert>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="indigo darken-1 white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="addNewPatient"
        >
          حفظ
        </v-btn>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      الرجاء ملئ جميع الحقول
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    props: ["AddPatientChronicDrugsDialog"],
    data() {
      return {
        profilePicture: null,
        formData: new FormData(),
        patientDataFromSearch: null,
        patientNameForSearch: "",
        drugId: 0,
        searchMedicine: "",
        genders: [
          { id: 1, val: "ذكر", eng: "male" },
          { id: 2, val: "انثى", eng: "female" },
        ],
        drugRoutes: [
          "syrup",
          "bottle",
          "vial",
          "ampule",
          "tablet",
          "capsule",
          "liquid",
          "sachet",
          "cream",
          "gel",
          "soap",
          "spray",
          "drops",
          "plaster",
          "paste",
          "patch",
          "inhalation",
          "solution",
          "suspension",
          "suppository",
        ],
        mobile: "",
        mobileRule: [
          (v) =>
            (this.isGovClinic && (v === "" || v == null)) || // Allow empty value if isGovClinic is true
            /^7[3-9][0-9]{8}$/.test(v) ||
            "اكتب رقم الهاتف بصورة صحيحة وبدون صفر في البداية",
        ],
        snackbar: false,
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        loadingSpinner: false,
        alrtState: false,
        alrtMsg: "حدث خطاء, فشل في الاضافة",
        alrtType: "info",
        Info: {
          patientId: 0,
          fullname: "",
          mobile: "",
          dob: "",
          bloodType: null,
          drugAllergy: "",
          gender: 0,
          city: 0,
          address: "",
          profession: "",
          selectedMedicines: null,
          issueDate: null,
          expDate: null,
          number: null,
          nationalId: null,
          profilePicture: null,
        },

        bloodTypes: [
          "لااعلم",
          "A+",
          "A-",
          "B+",
          "B-",
          "O+",
          "O-",
          "AB+",
          "AB-",
        ],
        governorates: [
          { id: 1, val: "بغداد-الكرخ" },
          { id: 2, val: "بغداد-الرصافة" },
          { id: 3, val: "البصرة" },
          { id: 4, val: "نينوى" },
          { id: 5, val: "ذي قار" },
          { id: 6, val: "ميسان" },
          { id: 7, val: "المثنى" },
          { id: 8, val: "واسط" },
          { id: 9, val: "الديوانية" },
          { id: 10, val: "بابل" },
          { id: 11, val: "كربلاء المقدسة" },
          { id: 12, val: "النجف الاشرف" },
          { id: 13, val: "الانبار" },
          { id: 14, val: "صلاح الدين" },
          { id: 15, val: "ديالى" },
          { id: 16, val: "كركوك" },
          { id: 17, val: "اربيل" },
          { id: 18, val: "السليمانية" },
          { id: 19, val: "دهوك" },
        ],
        selectedMedicines: [],
        quantity: null,
        dose: null,
        route: null,
        drug: null,
      };
    },

    created() {
      if (this.getMedicines("chronic").length == 0) this.fetchClinicMedicines();
    },

    computed: {
      ...mapGetters([
        "getPatientsList",
        "getRole",
        "isGovClinic",
        "getMedicines",
        "getMedicineById",
      ]),
    },

    methods: {
      ...mapActions([
        "addPatientChronicDrugs",
        "fetchPatientsList",
        "fetchClinicMedicines",
      ]),
      text: (item) => item.fullname + "-" + (item.mobile?.substring(3) ?? ""),
      removePrescreptionFromList(i) {
        this.selectedMedicines.splice(i, 1);
      },
      setExpDateOneYearAfterIssueDate() {
        if (this.Info.issueDate) {
          // Clone the issueDate to avoid mutating it directly
          const issueDate = new Date(this.Info.issueDate);

          // Calculate the expDate by adding 1 year to the issueDate
          const expDate = new Date(
            issueDate.getFullYear() + 1,
            issueDate.getMonth(),
            issueDate.getDate()
          );

          // Update the expDate in the Info object
          this.Info.expDate = expDate.toISOString().substr(0, 10); // Format as YYYY-MM-DD
        }
      },
      onIssueDateChange() {
        // Call the method to update expDate when issueDate changes
        this.setExpDateOneYearAfterIssueDate();
      },

      addMedicineToList() {
        var medicine = {};
        if (!this.drugId) {
          this.snackbar = true;
          return;
        }
        medicine = {
          drug: this.drug,
          drugId: this.drugId,
          quantity: this.quantity,
        };
        this.drugId = 0;
        this.quantity = null;
        this.route = null;
        this.dose = null;
        this.selectedMedicines.push(medicine);
      },
      updatePrescriptionFields() {
        if (this.drugId) {
          var selectedMedicine = this.getMedicineById(this.drugId);

          console.log("selectedMedicine", selectedMedicine);
          // Check if the selectedMedicine is not null or undefined
          if (selectedMedicine) {
            this.dose = selectedMedicine.dosage || "";
            this.route = selectedMedicine.drug_form || "";
            this.drug = selectedMedicine.drug_name || "";
          }
          this.$refs.repeating_ref.focus();
        }
      },
      fetchClinicMedFn() {
        this.fetchClinicMedicines();
      },
      searchForPatients() {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.timer = setTimeout(() => {
          if (this.patientNameForSearch != "")
            this.fetchPatientsList(this.patientNameForSearch);
        }, 800);
      },

      addNewPatient() {
        if (!this.$refs.new_patient_form.validate()) return;
        this.formData = new FormData();

        this.alrtState = false;

        if (this.mobile == ""||this.mobile==null) this.Info.mobile = null;
        else this.Info.mobile = "964" + this.mobile;
        this.Info.selectedMedicines = JSON.stringify(this.selectedMedicines);
        /*  if (this.selectedMedicines.length == 0) {
          this.alrtState = true;
          this.alrtMsg = "الرجاء اختيار الادوية";
          this.alrtType = "warning";
          return;
        } */

        this.formData.append("patientId", this.Info.patientId);
        this.formData.append("fullname", this.Info.fullname);
        this.formData.append("mobile", this.Info.mobile);
        this.formData.append("dob", this.Info.dob);
        this.formData.append("bloodType", this.Info.bloodType);
        this.formData.append("drugAllergy", this.Info.drugAllergy);
        this.formData.append("gender", this.Info.gender);
        this.formData.append("city", this.Info.city);
        this.formData.append("address", this.Info.address);
        this.formData.append("profession", this.Info.profession);
        this.formData.append("selectedMedicines", this.Info.selectedMedicines);
        this.formData.append("issueDate", this.Info.issueDate);
        this.formData.append("expDate", this.Info.expDate);
        this.formData.append("number", this.Info.number);
        this.formData.append("nationalId", this.Info.nationalId);
        this.formData.append("profilePicture", this.Info.profilePicture);

        this.loadingSpinner = true;
        this.addPatientChronicDrugs(this.formData)
          .then(() => {
            this.alrtState = true;
            this.alrtMsg = "عملية الاضافة تمت بنجاح";
            this.alrtType = "success";
            this.loadingSpinner = false;
            this.Info.selectedMedicines = null;
            this.selectedMedicines = [];
            this.$refs.new_patient_form.reset();

            setTimeout(() => {
              this.alrtState = false;
            }, 2500);
          })
          .catch((err) => {
            console.log("error", err);
            console.log("error", err.response.data.message);
            if (
              err.response.data.message &&
              err.response.data.message == "mobile already exist"
            )
              this.alrtMsg = "رقم الهاتف الذي ادخلته مسجل لدينا مسبقا";
            if (
              err.response.data.message &&
              err.response.data.message == "The exp date must be a date after issue date."
            )
              this.alrtMsg = "يجب ان يكون تاريخ الانتهاء اكبر من تاريخ الاصدار!";
            else if (err.response.data.errors) {
              if (
                err.response.data.errors.mobile &&
                err.response.data.errors.mobile[0] ==
                  "The mobile has already been taken."
              )
                this.alrtMsg = "رقم الهاتف الذي ادخلته مسجل لدينا مسبقا";
            } else this.alrtMsg = "حدث خطاء, فشل في الاضافة";
            this.loadingSpinner = false;
            this.alrtState = true;
            this.alrtType = "error";
          })
          .finally(() => {
            this.loadingSpinner = false;
          });
      },

      closeDialog() {
        this.alrtState = false;
        this.$refs.new_patient_form.reset();

        this.$emit("addPatientChronicDrugsDialogClose", false);
      },
    },
    watch: {
      "Info.issueDate": function (newIssueDate) {
        this.setExpDateOneYearAfterIssueDate();
      },
      patientDataFromSearch(newVal) {
        if (newVal != null && typeof newVal === "object") {
          console.log(newVal);
          this.Info.patientId = newVal.id;
          this.Info.fullname = newVal.fullname;
          this.mobile = newVal.mobile
            ? newVal.mobile.startsWith("964")
              ? newVal.mobile.substring(3, newVal.mobile.length)
              : newVal.mobile
            : null;
          this.Info.dob = newVal.date_of_birth;

          this.Info.bloodType = newVal.blood_type;
          this.Info.drugAllergy = newVal.drug_allergy;
          this.Info.address = newVal.address;
          this.Info.profession = newVal.profession;
          this.Info.nationalId = newVal.national_id;
          this.profilePicture = newVal.profile_picture;
          this.governorates.forEach((gov) => {
            if (gov.val == newVal.city) this.Info.city = gov.id;
          });
          this.genders.forEach((gend) => {
            if (gend.eng == newVal.gender) this.Info.gender = gend.id;
          });
          //this.$refs.issueDateRef.focus();
        } else {
          this.Info.patientId = 0;
          this.Info.fullname = newVal;
          this.mobile = "";
          this.Info.dob = "";
          this.Info.bloodType = null;
          this.Info.drugAllergy = "";
          this.Info.gender = 0;
          this.Info.city = 0;
          this.Info.address = "";
          this.Info.profession = "";
          this.Info.nationalId = "";
          this.profilePicture = null;

          this.$refs.new_patient_form.resetValidation();
        }
        const today = new Date();
        const formattedToday = today.toISOString().substr(0, 10);
        this.Info.issueDate = formattedToday;
      },
    },
  };
</script>

<style scoped lang="scss">
  .remove-prescription {
    position: absolute;
    left: 2rem;
    top: 35%;
  }
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
</style>

import axios from "@/axios";
import store from "@/store";

const state = {
  patient_reservations: {},
  medicines: [],
  patients: [],
  patientBiometrics: [],
  analysisTypes: [],
};

const getters = {
  getPatientReservation: (state) => (id) => {
    return state.patient_reservations.data.find(
      (reservation) => reservation.id === id
    );
  },

  getPatientsList: (state) => state.patients,
  allPatientReservations: (state) => state.patient_reservations.data,
  getMedicines: (state) => (type) => {
    console.log();
    if (store.getters["isGovClinic"]) {
      if (type === "normal")
        return state.medicines
          .filter((medicine) => medicine.type === "normal")
          .sort((a, b) => a.drug_name.localeCompare(b.drug_name));
      else
        return state.medicines.filter((medicine) => medicine.type == "chronic");
    }
    return state.medicines;
  },
  getMedicineById: (state) => (id) => {
    if (id) return state.medicines.find((medicine) => medicine.id === id);
  },
  /*  getClinicMedicines: (state) => (searchTerm) => {
    const normalizedSearchTerm = searchTerm.toLowerCase();
    return state.medicines.filter((medicine) =>
      medicine.drugName.toLowerCase().includes(normalizedSearchTerm)
    );
  }, */
  getPatientBiometricsData: (state) => state.patientBiometrics,
  getPrCurrentPage: (state) => state.patient_reservations.current_page,
  getPrLastPage: (state) => state.patient_reservations.last_page,
  getAnalysisTypes: (state) => state.analysisTypes,
};
const actions = {
  async fetchPatientsList({ commit }, search) {
    const response = await axios.get("/clinic/patients?search=" + search);
    if (response.status == 200) commit("SET_PATIENTS", response.data);
  },
  async fetchAnalysisTypes({ commit }) {
    const response = await axios.get("/clinic/analysis-types");
    if (response.status == 200)
      commit("SET_ANALYSIS_TYPES", response.data.data);
  },
  async addMedicine({ commit }, name) {
    const response = await axios.post("/clinic/drugs", {
      drugName: name,
    });
    if (response.status == 200) commit("ADD_MEDICINE", name);
  },

  async fetchClinicMedicines({ commit }) {
    var response = await axios.get("/clinic/clinic-drugs");
    if (response.data.length > 0) commit("SET_MEDICINES", response.data);
  },
  async fetchMedicines({ commit }, name) {
    var response = await axios.get("/clinic/drugs?search=" + name);
    if (response.data.length > 0) commit("SET_MEDICINES", response.data);
    /*   else {
      response = await axios({
        url: "/api/rxterms/v3/search?terms=" + name,
        baseURL: "https://clinicaltables.nlm.nih.gov",
      });
      if (response.data[1].length > 0)
        commit("SET_MEDICINES", response.data[1]);
    } */
  },
  async fetchPatientReservations({ commit }, info) {
    const response = await axios.get(
      "/clinic/reservations?per_page=10&&page=" +
        info.page +
        "&&search=" +
        info.keyword +
        "&&filter=" +
        info.displayType
    );
    commit("SET_PATIENT_RESERVATION", response.data);
  },

  async fetchPatientMedicalHistory({ commit }, id) {
    const response = await axios.get(
      "/clinic/patients/" + id + "/medicalHistory"
    );
    if (response.status == 200) {
      commit("SET_PATIENT_MEDICAL_HISTORY", response.data.data);
      return "success";
    } else return "failed";
  },
  async addPatientMedicalHistory({ commit }, info) {
    const response = await axios.post(
      "/clinic/patients/" + info.id + "/medicalHistory",
      { medicalHistory: info.medicalHistory, _method: "put" }
    );
    if (response.status == 200) {
      commit("SET_PATIENT_MEDICAL_HISTORY", response.data.data);
      return "success";
    } else return "failed";
  },
  async fetchPatientBiometrics({ commit }, id) {
    const response = await axios.get("/clinic/patients/" + id + "/biometrics");
    if (response.status == 200) {
      commit("SET_PATIENT_BIOMETRICS", response.data);
      return "success";
    } else return "failed";
  },
  async fetchBiometricsOfReservation({ commit }, id) {
    const response = await axios.get(
      "/clinic/reservations/" + id + "/biometrics"
    );
    if (response.status == 200) {
      commit("SET_RESERVATION_BIOMETRICS", response.data.data);
      return "success";
    } else return "failed";
  },
  async addBiometrics({ commit }, info) {
    const response = await axios.post(
      "/clinic/reservations/" + info.id + "/biometrics",
      { biometrics: info.biometrics, _method: "put" }
    );
    if (response.status == 200) {
      commit("SET_RESERVATION_BIOMETRICS", response.data.data);
      return "success";
    } else return "failed";
  },
  async addDoctorNote({ commit }, info) {
    const response = await axios.post(
      "/clinic/patients/" + info.id + "/doctorNotes",
      { note: info.doctorNote }
    );
    if (response.status == 200) {
      commit("ADD_DOCTOR_NOTE", response.data.data);
      return "success";
    } else return "failed";
  },
  async changeAnalysisPaidStatus({ _ }, analysis) {
    const response = await axios.post(
      "/clinic/patient-analyses/" + analysis.id,
      { is_paid: analysis.is_paid, _method: "put" }
    );
    if (response.status == 200) {
      return "success";
    } else return "failed";
  },
  async addDoctorComment({ commit }, info) {
    const response = await axios.post(
      "/clinic/reservations/" + info.id + "/comments",
      { comment: info.comment }
    );
    commit("ADD_DOCTOR_COMMENT", response.data.data);
    return response;
  },
  async updatePatient({ commit }, info) {
    const response = await axios.post(
      "/clinic/patients/" + info.patientId,
      info
    );
    commit("UPDATE_PATIENT", response.data.data);
    return response;
  },
  async addAnalysis({ commit }, info) {
    const response = await axios.post(
      "/clinic/reservations/" + info.reservation_id + "/patient-analyses",
      info
    );
    commit("ADD_ANALYSIS", {
      analyses: response.data.data,
      reservationId: info.reservation_id,
    });
    return response.data.message;
  },
  async addDoctorPrescription({ commit }, info) {
    const response = await axios.post(
      "/clinic/reservations/" + info.id + "/prescription",
      { prescription: info.prescription }
    );
    commit("ADD_DOCTOR_PRESCRIPTION", response.data.data);
    return response.data.message;
  },

  async newPatientAndReservation({ commit }, info) {
    const response = await axios.post("/clinic/patients/reservations", info);
    if (response.status == 200) commit("ADD_NEW_RESERVATION", response.data);

    console.log(response);
  },

  async changeStatus({ commit }, info) {
    const response = await axios.post(
      "/clinic/reservations/" + info.id + "/status",
      info
    );
    if (response.status == 200) {
      if (
        response.data.message == "updated" ||
        response.data.message == "created"
      )
        commit("CHANGE_STATUS", response.data.data);
      return response.data;
    }
  },
  async changeResDoctor(_, info) {
    const response = await axios.post(
      "/clinic/reservations/" + info.id + "/update-doctor",
      { doctorId: info.doctorId }
    );
    if (response.status == 200) {
      return response.data.message;
    }
  },
  async updatePatientType(_, info) {
    const response = await axios.post(
      "/clinic/reservations/" + info.id + "/patient-type",
      { type: info.type }
    );
    if (response.status == 200) {
      return response.data.message;
    }
  },

  setPrCurrentPage({ commit }, currentPage) {
    commit("SET_PR_CURRENT_PAGE", currentPage);
  },
};
const mutations = {
  SET_PATIENTS: (state, data) => (state.patients = data),
  SET_PATIENT_RESERVATION: (state, data) => (state.patient_reservations = data),
  SET_PR_CURRENT_PAGE: (state, data) =>
    (state.patient_reservations.current_page = data),
  SET_ANALYSIS_TYPES: (state, data) => (state.analysisTypes = data),

  ADD_NEW_RESERVATION: (state, data) => {
    state.patient_reservations.data.unshift(data);
  },
  ADD_DOCTOR_NOTE: (state, data) => {
    state.patient_reservations.data.forEach((item, i) => {
      if (item.patient.id == data.id) {
        state.patient_reservations.data[i].patient.doctor_note =
          data.doctor_note;
      }
    });
  },
  UPDATE_PATIENT: (state, data) => {
    state.patient_reservations.data.forEach((item, i) => {
      if (item.patient.id == data.id) {
        state.patient_reservations.data[i].patient.fullname = data.fullname;
        state.patient_reservations.data[i].patient.profile_picture =
          data.profile_picture;
      }
    });
  },
  SET_PATIENT_MEDICAL_HISTORY: (state, data) => {
    state.patient_reservations.data.forEach((item, i) => {
      if (item.patient.id == data.id) {
        state.patient_reservations.data[i].patient.medical_history =
          data.medical_history;
      }
    });
  },
  SET_PATIENT_BIOMETRICS: (state, data) => (state.patientBiometrics = data),
  SET_RESERVATION_BIOMETRICS: (state, data) => {
    const i = state.patient_reservations.data
      .map((item) => item.id)
      .indexOf(data.id);
    state.patient_reservations.data[i].biometrics = data.biometrics;
  },
  ADD_DOCTOR_COMMENT: (state, data) => {
    const i = state.patient_reservations.data
      .map((item) => item.id)
      .indexOf(data.id);
    state.patient_reservations.data[i].doctor_comment = data.doctor_comment;
  },
  ADD_DOCTOR_PRESCRIPTION: (state, data) => {
    const i = state.patient_reservations.data
      .map((item) => item.id)
      .indexOf(data.id);
    state.patient_reservations.data[i].prescription = data.prescription;
    state.patient_reservations.data[i].status = data.status;
  },
  ADD_ANALYSIS: (state, data) => {
    const i = state.patient_reservations.data
      .map((item) => item.id)
      .indexOf(data.reservationId);

    for (const analysis of data.analyses) {
      state.patient_reservations.data[i].analyses.push(analysis);
    }
  },
  CHANGE_STATUS: (state, data) => {
    const i = state.patient_reservations.data
      .map((item) => item.id)
      .indexOf(data.id);
    state.patient_reservations.data[i].status = data.status;
    state.patient_reservations.data[i].cancel_reason_doctor =
      data.cancel_reason_doctor;
  },
  SET_MEDICINES: (state, data) => (state.medicines = data),
  ADD_MEDICINE: (state, data) => {
    state.medicines.push(data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

import axios from "@/axios";
const state = {
  token: "",
  userInfo: {},
};
const getters = {
  authenticated: (state) => state.token && state.userInfo,
  getUser: (state) => state.userInfo,
  getRole: (state) => state.userInfo.role,
  getSubscriptionType: (state) => {
    if (state.userInfo) return state.userInfo.subscriptionStatus;
  },
  getClinicId: (state) => state.userInfo.clinicId,
  getFeatures: (state) => {
    return state.userInfo.features !== null ? state.userInfo.features : [];
  },
  isGovClinic: (state) => {
    if (state.userInfo.role == "clinic_assistant") {
      return state.userInfo.clinicAssistantInfo.clinic.is_gov_clinic
        ? true
        : false;
    } else if (state.userInfo.role == "doctor") {
      return state.userInfo.doctorInfo.clinic.is_gov_clinic ? true : false;
    } else return false;
  },
  isClinicAdmin: (state) => {
    return (
      state.userInfo.features &&
      state.userInfo.features.includes("clinic_admin")
    );
  },
};
const actions = {
  async updateDoctorStatus({ commit }, status) {
    const response = await axios.post("/clinic/doctors/changeDoctorStatus", {
      activity_status: status,
    });
    if (response.data.success == "true") {
      commit("SET_DOCTOR_ACTIVITY", response.data.data.activity_status);
      return response.data.message;
    }
  },
  async loginFun({ dispatch }, cred) {
    var obj = JSON.parse(cred);
    const response = await axios.post("/auth/login", {
      username: obj.username,
      password: obj.password,
    });
    if (response.data.message == "invalid_username_or_password")
      return "incorrect";
    else if (response.data.message != "success") return "failed";
    return dispatch("attempt", response.data.token);
  },
  async attempt({ commit, state }, token) {
    if (token) commit("setToken", token);
    if (!state.token) return;

    try {
      const response = await axios.post("/auth/me");
      if (
        response.data.role != "doctor" &&
        response.data.role != "secretary" &&
        response.data.role != "clinic_assistant"
      ) {
        return "unAuthorized";
      } else {
        localStorage.setItem("userInfo", JSON.stringify(response.data));
        commit("setUser", response.data);
        return "success";
      }
    } catch {
      console.log("err");
      commit("setToken", null);
      commit("setUser", null);
      return "error";
    }
  },
  signout({ commit }) {
    return axios.post("/auth/logout").then(() => {
      commit("setToken", null);
      commit("setUser", null);
      localStorage.removeItem("user_token");
    });
  },
};
const mutations = {
  setToken: (state, user_token) => (state.token = user_token),
  setUser: (state, userInfo) => (state.userInfo = userInfo),
  SET_USER_CREDIT_CARD: (state, data) => {
    state.userInfo.creditCards.unshift(data);
  },
  SET_DOCTOR_ACTIVITY: (state, newStatus) =>
    (state.userInfo.doctorInfo.activity_status = newStatus),
};
export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="login fill-height overflow-auto">
    <v-container fill-height>
      <v-card
        elevation="1"
        class="pa-5 pa-md-10 mx-auto rounded-lg align-center justify-center"
        width="90%"
        height="auto"
      >
        <v-row>
          <v-col cols="12" md="5">
            <h3 class="d-block d-md-none">
              صحة تايم |
              <span class="deep-purple--text darken-1">Time.health</span>
            </h3>
            <br />
            <h1 class="mb-4 d-none d-md-block">تسجيل الدخول الى النظام</h1>
            <h5 class="mb-4 d-block d-md-none">
              تسجيل الدخول الى لوحة تحكم العيادة
            </h5>
            <h2 class="mb-8 d-none d-md-block">Login to system</h2>

            <v-form ref="login_form" lazy-validation>
              <v-alert
                v-if="feedback"
                border="left"
                color="red lighten-2"
                dark
                >{{ feedback }}</v-alert
              >
              <v-text-field
                v-model="cred.username"
                :rules="requiredRule"
                label="اسم المستخدم"
                required
                class="mb-3"
              ></v-text-field>

              <v-text-field
                v-model="cred.password"
                :rules="requiredRule"
                label="كلمة السر"
                required
                class="mb-8"
                type="password"
                @keydown.enter="login()"
              ></v-text-field>

              <v-btn
                color="deep-purple  white--text"
                class="mb-3"
                @click="login()"
                :loading="loginLoading"
                :disabled="loginLoading"
                right
              >
                <v-icon>mdi-login</v-icon>
                تسجيل الدخول
              </v-btn>
            </v-form>
          </v-col>
          <v-col cols="7" class="d-none d-md-block">
            <img src="../../assets/imgs/doctor.svg" width="400" />

            <h2>
              صحة تايم |
              <span class="deep-purple--text darken-1">Time.health</span>
            </h2>
            <h4>لوحة تحكم العيادة</h4>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    name: "login-page",
    data: function () {
      return {
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],

        cred: {
          username: "",
          password: "",
        },
        feedback: "",
        timeout: null,
        loginLoading: false,
      };
    },
    mounted() {
      var token = localStorage.getItem("user_token");
      if (token)
        this.attempt(token).then((res) => {
          if (res == "success") this.$router.push("/dashboard/main");
        });
    },
    methods: {
      ...mapActions(["loginFun", "attempt"]),

      login() {
        if (!this.$refs.login_form.validate()) return;
        this.loginLoading = true;
        this.feedback = "";
        this.loginFun(JSON.stringify(this.cred))
          .then((msg) => {
            console.log(msg);
            if (msg == "success") {
              this.feedback = "";
              this.cred.username = "";
              this.cred.password = "";
              this.$router.push("/dashboard/main");
              console.log("log");
            } else if (msg == "unAuthorized") {
              this.feedback = "لاتمتلك صلاحية الدخول الى هذا المكان";
            } else if (msg == "incorrect") {
              this.feedback = "إسم المستخدم أو كلمة المرور غير صالحة";
            } else if (msg == "failed" || msg == "error") {
              this.feedback = "حدث خطاء الرجاء اعادة المحاولة لاحقا";
            }
            this.loginLoading = false;
          })
          .catch((err) => {
            if (!err.response) {
              this.feedback = "يرجى التاكد من الاتصال بالانترنت!";
            } else if (
              err.response.status == 504 ||
              err.response.status == 500 ||
              err.response.status == 503 ||
              err.response.status == 501
            )
              this.feedback = "مشكلة في الخادم يرجى المحاولة لاحقا";
            else if (
              err.response.data.message == "invalid_username_or_password"
            )
              this.feedback = "اسم مستخدم غير صحيح او كلمة مرور خاطئة";
            else this.feedback = "حدث خطاء الرجاء اعادة المحاولة لاحقا";
            this.loginLoading = false;
          });
      },
    },
  };
</script>
<style scoped lang="scss">
  @import "@/scss/colors.scss";

  .login {
    width: 100%;
    background: $cream;
    height: 100vh;
    text-align: center;
    direction: rtl;
    padding-top: 20px;
    padding-bottom: 20px;
    .card {
      background-color: $white;
    }
    .lgn-btn {
      background-color: $darkBlue;
    }
  }
</style>

<template>
  <v-dialog v-if="UpdateOfferDialog" :value="UpdateOfferDialog" width="500">
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">
          تعديل معلومات العرض
        </h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-alert dense outlined type="error" v-if="errorAlrt">
          حدث خطاء فشل في العملية
        </v-alert>
        <v-alert dense outlined type="success" v-if="successAlrt">
          عملية التحديث تمت بنجاح
        </v-alert>
        <v-form ref="offer_form" lazy-validation>
          <v-col cols="12">
            <v-text-field
              label="العنوان"
              v-model="offerData.title"
              :rules="requiredRule"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              label="نص العرض"
              :rules="requiredRule"
              v-model="offerData.body"
              rows="2"
            ></v-textarea>
          </v-col>

          <v-col cols="12" sm="6" class="float-right">
            <v-text-field
              label="السعر"
              type="number"
              :rules="requiredRuleNumber"
              v-model="offerData.price"
              suffix="دينار عراقي"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="float-right">
            <v-text-field
              label="الخصم بالمئة"
              type="number"
              :rules="requiredRuleNumber"
              v-model="offerData.discount_rate"
              prepend-icon="mdi-percent"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" class="float-right">
            <v-menu
              ref="offerFromDate"
              v-model="dateFromMenu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="offerData.from_date"
                  label="من تاريخ"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :clearable="trueValue"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="offerData.from_date"
                :active-picker.sync="activePicker"
                :min="new Date(Date.now()).toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6" class="float-right">
            <v-menu
              ref="offerToDate"
              v-model="dateToMenu"
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="offerData.to_date"
                  label="الى تاريخ"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :clearable="trueValue"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="offerData.to_date"
                :active-picker.sync="activePicker"
                :min="new Date(Date.now()).toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" class="float-right">
            <v-file-input
              accept="image/png, image/jpeg, image/jpg"
              placeholder="اختر الصور"
              prepend-icon="mdi-image"
              label="الصور ان وجدت"
              multiple
              v-model="newImages"
            ></v-file-input>
          </v-col>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="indigo white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="updateOfferFun"
        >
          حفظ
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>

        <v-btn color="primary" text @click="closeDialog">
          اغلاق
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["UpdateOfferDialog", "offerDetails"],
  watch: {
    offerDetails(new_value) {
      this.offerData = Object.assign({}, new_value);
    },
  },
  data() {
    return {
      trueValue: true,
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      requiredRuleNumber: [
        (v) => {
          if (parseInt(v) >= 0) return true;
          return "هذا الحقل مطلوب";
        },
      ],
      offerData: Object.assign({}, this.offerDetails),
      loadingSpinner: false,
      errorAlrt: false,
      successAlrt: false,
      activePicker: null,
      dateFromMenu: false,
      dateToMenu: false,
      newImages: [],
      formData: new FormData(),
    };
  },

  methods: {
    ...mapActions(["updateOffer"]),
    closeDialog() {
      this.newImages = [];
      this.$emit("updateOfferDialogClose", false);
    },
    handleClickOutSide(e) {
      if (e.target.className == "v-overlay__scrim") this.closeDialog();
    },
    updateOfferFun() {
      if (!this.$refs.offer_form.validate()) return;
      this.loadingSpinner = true;

      this.formData.append("id", this.offerData.id);
      this.formData.append("title", this.offerData.title);
      this.formData.append("body", this.offerData.body);
      this.formData.append("price", this.offerData.price);
      this.formData.append("discountRate", this.offerData.discount_rate);
      this.formData.append("fromDate", this.offerData.from_date);
      this.formData.append("toDate", this.offerData.to_date);
      this.formData.append("_method", "put");

      for (let i = 0; i < this.newImages.length; i++) {
        this.formData.append("images[" + i + "]", this.newImages[i]);
      }

      this.updateOffer(this.formData)
        .then((msg) => {
          if (msg == "success") {
            this.loadingSpinner = false;
            this.successAlrt = true;
            setTimeout(() => {
              this.formData = new FormData();
              this.newImages = [];
              this.successAlrt = false;
              this.errorAlrt = false;
              this.closeDialog();
            }, 3000);
          }
        })
        .catch(() => {
          this.errorAlrt = true;
          this.successAlrt = false;
          this.loadingSpinner = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>

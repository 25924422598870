import axios from "@/axios";
const state = {
  doctor: {},
  doctorsList: [],
};

const getters = {
  getDoctor: (state) => state.doctor,
  getDoctorsList: (state) => state.doctorsList,
};
const actions = {
  async setDoctor({ commit }) {
    const response = await axios.get("/clinic/doctors/getInfo");
    commit("SET_DOCTOR", response.data);
  },
  async fetchDoctorsList({ commit }) {
    const response = await axios.get("/clinic/clinics/doctors");
    commit("SET_DOCTORS_LIST", response.data.data);
  },

  async updateDoctorData({ commit }, doctorForm) {
    axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
    const response = await axios.post(
      "/clinic/doctors/updateInfo/" + doctorForm.get("doctorId"),
      doctorForm
    );
    if (response.data.message == "updated") {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      var fullname = response.data.data.fullname;
      userInfo.fullname = fullname;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      commit("UPDATE_DOCTOR", response.data.data);
    }
    return response.data.message;
  },
};
const mutations = {
  SET_DOCTOR: (state, data) => (state.doctor = data),
  SET_DOCTORS_LIST: (state, data) => (state.doctorsList = data),
  UPDATE_DOCTOR: (state, data) => {
    state.doctor = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

<template>
  <div class="chronic-notebook">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-1" style="font-family: cairo !important">
        سجل الامراض المزمنة
      </div>

      <v-card class="pb-1">
        <v-card-title class="">
          <v-row dense>
            <v-col cols="12" sm="8" md="10">
              <v-btn
                v-if="isGovClinic"
                style="letter-spacing: 0"
                class="float-right ml-2 mb-2"
                @click="showPatientCardDialog"
                color="pink white--text"
              >
                <v-icon left>mdi-plus-box-outline</v-icon>
                بطاقة الامراض المزمنة
              </v-btn>
              <v-text-field
                dense
                v-model="Info.keyword"
                append-icon="mdi-magnify"
                label="بحث"
                hide-details
                solo
                class="d-inline-block mt-0 mb-2 ml-0 ml-md-2"
                style="vertical-align: center; margin-top: 0"
                @keyup.enter="searchFn()"
              >
                <template v-slot:append>
                  <v-btn
                    style="vertical-align: center"
                    :loading="loadingSearch"
                    :disabled="loadingSearch"
                    color="blue darken-1"
                    plain
                    class="float-right my-auto"
                    @click="searchFn()"
                  >
                    <v-icon left>mdi-magnify</v-icon>

                    بحث
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          align-center
          :headers="headers"
          :items="getChronicNotebook"
          :items-per-page="itemPerPage"
          hide-default-footer
          fixed-header
          dir="rtl"
          style="overflow: hidden !important"
        >
          <template v-slot:item.fullname="{ item }">
            <v-avatar v-if="item.patient.profile_picture">
              <img :src="item.patient.profile_picture" alt="" />
            </v-avatar>
            <span
              @click="showPatientProfileDialog(item.patient)"
              class="fullname-style mr-1"
              >{{ item.patient.fullname }}</span
            >
          </template>
          <template v-slot:item.date="{ item }">
            {{ item.created_at.substring(0, 10) }}
          </template>

          <template v-slot:item.drugs="{ item }">
            <v-btn
              icon
              dense
              color="grey"
              class="mx-2"
              @click="
                showPatientChronicDrugs(
                  item.patient,
                  item.clinic_drugs,
                  item.id
                )
              "
            >
              <v-icon dark color="green">mdi-prescription</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.printBadge="{ item }">
            <v-btn
              icon
              color="blue"
              class="mx-2"
              @click="
                showPrintBadgeDialog(
                  item.patient,
                  item.clinic,
                  item.issue_date,
                  item.expiration_date,
                  item.number
                )
              "
            >
              <v-icon dark>mdi-printer</v-icon>
            </v-btn>
          </template>

          <template v-slot:item.delete="{ item }">
            <v-btn
              icon
              color="red"
              class="mx-2"
              @click="showDeleteChronicBookDialog(item.id, item.number)"
            >
              <v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          :disabled="disabledPagination"
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
        ></v-pagination>
        <!-- pagination -->
      </v-card>
      <print-badge-dialog
        :PrintBadgeDialog="PrintBadgeDialog"
        :patientData="patientData"
        :issueDate="cardIssueDate"
        :expirationDate="cardExpirationDate"
        :cardNumber="cardNumber"
        :clinicData="clinicData"
        @printBadgeDialogClose="PrintBadgeDialog = $event"
      />

      <PatientChronicDrugs
        :PatientChronicDrugs="PatientChronicDrugs"
        :patientData="patientData"
        :drugsData="drugsData"
        :notebookId="notebookId"
        @patientChronicDrugsClose="PatientChronicDrugs = $event"
      />
      <AddPatientChronicDrugs
        :AddPatientChronicDrugsDialog="AddPatientChronicDrugsDialog"
        @addPatientChronicDrugsDialogClose="
          AddPatientChronicDrugsDialog = $event
        "
      />
      <patient-profile
        :patientData="patientData"
        :PatientProfileDialog="PatientProfileDialog"
        @patientProfileDialogClose="PatientProfileDialog = $event"
      />
      <DeleteChronicBookDialog
        :DeleteChronicBookDialog="DeleteChronicBookDialog"
        :id="notebookId"
        :cardNumber="cardNumber"
        @deleteChronicBookDialogClosed="DeleteChronicBookDialog = $event"
      />
    </v-container>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import PrintBadgeDialog from "../../components/models/patient_reservations/PrintBadgeDialog";
  import AddPatientChronicDrugs from "../../components/models/patient_reservations/AddPatientChronicDrugs";
  import PatientChronicDrugs from "../../components/models/patient_reservations/PatientChronicDrugs";
  import PatientProfile from "../../components/models/patient_reservations/PatientProfile";
  import DeleteChronicBookDialog from "../../components/models/chronic_notebook/DeleteChronicBookDialog";

  export default {
    name: "PatientChronicNotebookPage",
    components: {
      PrintBadgeDialog,
      AddPatientChronicDrugs,
      PatientChronicDrugs,
      PatientProfile,
      DeleteChronicBookDialog,
    },
    data() {
      return {
        PatientProfileDialog: false,
        DeleteChronicBookDialog: false,
        AddPatientChronicDrugsDialog: false,
        PatientChronicDrugs: false,
        PrintBadgeDialog: false,
        cardNumber: null,
        patientData: null,
        clinicData: null,
        notebookId: null,
        drugsData: null,
        cardIssueDate: null,
        cardExpirationDate: null,
        disabledPagination: false,
        loadingSearch: false,
        headers: [
          { text: "اسم المراجع", value: "fullname" },
          { text: "الهوية الوطنية", value: "patient.national_id" },
          { text: "رقم الهاتف", value: "patient.mobile" },
          { text: "رقم البطاقة", value: "number" },
          { text: "تاريخ الاصدار", value: "issue_date" },
          { text: "تاريخ الانتهاء", value: "expiration_date" },
          { text: "الادوية", value: "drugs" },
          { text: "طباعة الهوية", value: "printBadge" },
          { text: "حذف", value: "delete" },
        ],
        itemPerPage: 10,
        selectedPage: 1,
        Info: {
          page: 1,
          keyword: "",
        },
      };
    },
    watch: {
      currentGet(newVal) {
        this.Info.page = newVal;
        this.disabledPagination = true;
        this.fetchChronicNotebook(this.Info)
          .then(() => (this.disabledPagination = false))
          .catch(() => (this.disabledPagination = false));
      },
    },
    created() {
      this.disabledPagination = true;
      this.fetchChronicNotebook(this.Info)
        .then(() => (this.disabledPagination = false))
        .catch(() => (this.disabledPagination = false));
    },
    computed: {
      ...mapGetters(["getChronicNotebook", "isGovClinic"]),

      currentGet: {
        get() {
          return this.$store.getters.getChronicNotebookCurrentPage;
        },
        set(value) {
          this.$store.dispatch("setChronicCurrentPage", value);
        },
      },
      lastGet: {
        get() {
          return this.$store.getters.getChronicNotebookLastPage;
        },
      },
    },

    methods: {
      ...mapActions(["fetchChronicNotebook"]),
      showDeleteChronicBookDialog(id, number) {
        if (id) {
          this.notebookId = id;
          this.cardNumber = number;
          this.DeleteChronicBookDialog = true;
        }
      },
      showPatientCardDialog() {
        this.AddPatientChronicDrugsDialog = true;
      },
      showPatientProfileDialog(patient) {
        this.patientData = patient;
        this.PatientProfileDialog = true;
      },
      showPatientChronicDrugs(patient, drugs, notebookId) {
        if (patient) {
          this.notebookId = notebookId;
          this.patientData = patient;
          this.drugsData = drugs;
          this.PatientChronicDrugs = true;
        }
      },
      showPrintBadgeDialog(patient, clinic, issue, expiry, cardNumber) {
        if (patient) {
          this.patientData = patient;
          this.clinicData = clinic;
          this.PrintBadgeDialog = true;
          this.cardIssueDate = issue;
          this.cardExpirationDate = expiry;
          this.cardNumber = cardNumber;
        }
      },
      searchFn() {
        this.Info.page = 1;
        this.loadingSearch = true;
        this.Info.displayType = "all";
        this.disabledPagination = true;
        this.fetchChronicNotebook(this.Info)
          .then(() => {
            this.loadingSearch = false;
            this.disabledPagination = false;
          })
          .catch(() => {
            this.loadingSearch = false;
            this.disabledPagination = false;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chronic-notebook {
    max-height: 82vh;
    overflow: auto;
    .noScroll {
      overflow: hidden !important;
    }
  }

  .fullname-style {
    font-size: 14px;
    font-weight: bold;
    &:hover {
      color: #1976d2;
      cursor: pointer;
    }
  }
</style>

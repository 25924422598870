<template>
  <div class="notifications">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-1" style="font-family: cairo!important;">
        الاشعارات
      </div>

      <v-card class="pb-1">
        <v-card-title class="">
          <v-row dense>
            <!--    <v-col cols="12" sm="8" md="10">
              <v-text-field
                dense
                v-model="Info.keyword"
                append-icon="mdi-magnify"
                label="بحث"
                hide-details
                single-line
                class="d-inline-block"
              >
                <template v-slot:append>
                  <v-btn
                    :loading="loadingSearch"
                    :disabled="loadingSearch"
                    color="blue darken-1"
                    plain
                    class="float-right "
                    @click="searchInOffers()"
                  >
                    <v-icon left>mdi-magnify</v-icon>

                    بحث
                  </v-btn>
                </template>
              </v-text-field>
            </v-col> -->
          </v-row>
        </v-card-title>
        <v-data-table
          align-center
          :headers="headers"
          :items="allNotifications"
          :items-per-page="itemPerPage"
          hide-default-footer
          fixed-header
          dir="rtl"
          style="overflow: hidden!important"
        >
          <template v-slot:item.notificationStatus="{ item }">
            <small v-if="item.is_pushed == 0"
              ><v-icon color="blue darken-2">mdi-alarm-snooze</v-icon> انتظار
              الموافقة</small
            >
            <small v-else
              ><v-icon color="green">mdi-check-all</v-icon> تم الارسال</small
            >
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          :disabled="disabledPagination"
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
        ></v-pagination>
        <!-- pagination -->
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "NotificationsPage",
  components: {},
  watch: {
    currentGet(newVal) {
      this.Info.page = newVal;
      this.disabledPagination = true;
      this.fetchNotifications(this.Info)
        .then(() => (this.disabledPagination = false))
        .catch(() => (this.disabledPagination = false));
    },
  },
  created() {
    this.disabledPagination = true;
    this.fetchNotifications(this.Info)
      .then(() => (this.disabledPagination = false))
      .catch(() => (this.disabledPagination = false));
  },
  computed: {
    ...mapGetters(["allNotifications"]),

    currentGet: {
      get() {
        return this.$store.getters.getNotifCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setNotifCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getNotifLastPage;
      },
    },
  },

  data() {
    return {
      disabledPagination: false,
      loadingSearch: false,
      headers: [
        { text: "حالة الاشعار", value: "notificationStatus" },
        { text: "العنوان", value: "title" },
        { text: "نص التنبيه", value: "body" },
        { text: "تاريخ النشر", value: "created_at" },
      ],
      itemPerPage: 10,
      selectedPage: 1,
      Info: {
        page: 1,
        keyword: "",
      },
    };
  },

  methods: {
    ...mapActions(["fetchNotifications"]),
    /*   searchInOffers() {
      this.Info.page = 1;
      this.loadingSearch = true;
      this.fetchOffers(this.Info)
        .then(() => {
          this.loadingSearch = false;
        })
        .catch(() => {
          this.loadingSearch = false;
        });
    }, */
  },
};
</script>

<style lang="scss" scoped>
.notifications {
  max-height: 82vh;
  overflow: auto;
  .noScroll {
    overflow: hidden !important;
  }
}
</style>

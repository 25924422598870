<template>
  <div class="offers">
    <v-container fluid class="px-1 px-sm-1 px-md-2 px-lg-3">
      <div class="title mb-1" style="font-family: cairo!important;">
        العروض والاشتراكات
      </div>

      <v-card class="pb-1">
        <v-card-title class="">
          <v-row dense>
            <v-col cols="12">
              <v-btn
                class=" ml-1 mb-2"
                color="indigo white--text"
                @click="OffersDialog = true"
              >
                <v-icon left>mdi-plus</v-icon>
                اضافة عرض جديد
              </v-btn>
              <v-btn
                @click="showOffersSubscriptionsPage"
                class="t ml-1 mb-2"
                color="red white--text"
                ><v-icon left>mdi-credit-card-settings-outline</v-icon>
                الاشتراكات</v-btn
              >
              <v-text-field
                dense
                v-model="Info.keyword"
                append-icon="mdi-magnify"
                label="بحث"
                hide-details
                solo
                class="d-inline-block mt-0"
                style="vertical-align:center;margin-top:0"
              >
                <template v-slot:append>
                  <v-btn
                    :loading="loadingSearch"
                    :disabled="loadingSearch"
                    color="blue darken-1"
                    plain
                    class="float-right "
                    @click="searchInOffers()"
                  >
                    <v-icon left>mdi-magnify</v-icon>

                    بحث
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          align-center
          :headers="headers"
          :items="allOffers"
          :items-per-page="itemPerPage"
          hide-default-footer
          fixed-header
          dir="rtl"
          style="overflow: hidden!important"
        >
          <template v-slot:item.offerStatus="{ item }">
            <v-switch
              :input-value="item.is_hidden == null ? true : false"
              @change="changeOfferStatus(item.id)"
            ></v-switch>
          </template>
          <template v-slot:item.shortenBody="{ item }">
            <span @click="showFullbody(item.body)">
              {{ truncate(item.body, 100) }}</span
            >
          </template>
          <template v-slot:item.offerPeriod="{ item }">
            <span v-if="item.offer_period == 'once'"> لمرة واحدة</span>
            <span v-else>{{ item.offer_period }} شهر</span>
          </template>
          <template v-slot:item.editOffer="{ item }">
            <v-btn
              icon
              color="amber"
              class="mx-2"
              @click="updateOffer(item.id)"
            >
              <v-icon dark>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <!-- pagination -->
        <v-pagination
          :disabled="disabledPagination"
          v-model="currentGet"
          :length="lastGet"
          total-visible="6"
        ></v-pagination>
        <!-- pagination -->
      </v-card>
    </v-container>
    <!--   <delete-offer
      :id="offerId"
      :DeleteOfferDialog="DeleteOfferDialog"
      @deleteOfferDialogClose="DeleteOfferDialog = $event"
    /> -->
    <add-offer
      :OffersDialog="OffersDialog"
      @offersDialogClose="OffersDialog = $event"
    />
    <update-offer
      :UpdateOfferDialog="UpdateOfferDialog"
      :offerDetails="offerDetails"
      @updateOfferDialogClose="UpdateOfferDialog = $event"
    />
    <full-text-dialog
      :FullTextDialog="FullTextDialog"
      :text="text"
      @fullTextDialogClose="FullTextDialog = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
//import DeleteOffer from "../../components/models/notifications/DeleteOffer";
import AddOffer from "../../components/models/notifications/AddOffer";
import FullTextDialog from "../../components/models/global/FullTextDialog";
import UpdateOffer from "../../components/models/offer/UpdateOffer";

export default {
  name: "OffersPage",
  components: { AddOffer, FullTextDialog, UpdateOffer },
  watch: {
    currentGet(newVal) {
      this.Info.page = newVal;
      this.disabledPagination = true;
      this.fetchOffers(this.Info)
        .then(() => (this.disabledPagination = false))
        .catch(() => (this.disabledPagination = false));
    },
  },
  created() {
    this.disabledPagination = true;
    this.fetchOffers(this.Info)
      .then(() => (this.disabledPagination = false))
      .catch(() => (this.disabledPagination = false));
  },
  computed: {
    ...mapGetters(["allOffers", "getOffer"]),

    currentGet: {
      get() {
        return this.$store.getters.getOffersCurrentPage;
      },
      set(value) {
        this.$store.dispatch("setOffersCurrentPage", value);
      },
    },
    lastGet: {
      get() {
        return this.$store.getters.getOffersLastPage;
      },
    },
  },

  data() {
    return {
      disabledPagination: false,
      OffersDialog: false,
      offerId: 0,
      //   DeleteOfferDialog: false,
      UpdateOfferDialog: false,
      offerDetails: {},
      loadingSearch: false,
      FullTextDialog: false,
      text: "",
      headers: [
        { text: "العنوان", value: "title" },
        { text: "نص العرض", value: "shortenBody" },
        { text: "السعر", value: "price" },
        { text: "التخفيض", value: "discount_rate" },
        { text: "فترة العرض", value: "offerPeriod" },
        { text: "من تاريخ", value: "from_date" },
        { text: "الى تاريخ", value: "to_date" },
        { text: "الظهور", value: "offerStatus" },
        { text: "تعديل", value: "editOffer" },
      ],
      itemPerPage: 10,
      selectedPage: 1,
      Info: {
        page: 1,
        keyword: "",
      },
    };
  },

  methods: {
    ...mapActions(["fetchOffers", "toggleOfferState"]),
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + " ..." : str;
    },
    showOffersSubscriptionsPage() {
      this.$router.push({ name: "OffersSubscriptionsPage" });
    },
    showFullbody(body) {
      this.FullTextDialog = true;
      this.text = body;
    },
    updateOffer(id) {
      this.UpdateOfferDialog = true;
      this.offerDetails = this.getOffer(id);
    },
    searchInOffers() {
      this.Info.page = 1;
      this.loadingSearch = true;
      this.disabledPagination = true;
      this.fetchOffers(this.Info)
        .then(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        })
        .catch(() => {
          this.loadingSearch = false;
          this.disabledPagination = false;
        });
    },
    changeOfferStatus(id) {
      this.toggleOfferState(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.offers {
  max-height: 82vh;
  overflow: auto;
  .noScroll {
    overflow: hidden !important;
  }
}
</style>

import { render, staticRenderFns } from "./AddPatient.vue?vue&type=template&id=05760706&scoped=true"
import script from "./AddPatient.vue?vue&type=script&lang=js"
export * from "./AddPatient.vue?vue&type=script&lang=js"
import style0 from "./AddPatient.vue?vue&type=style&index=0&id=05760706&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05760706",
  null
  
)

export default component.exports
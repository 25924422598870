<template>
  <v-dialog v-if="NotificationDialog" :value="NotificationDialog" width="500">
    <v-card v-click-outside="closeDialog">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">
          ارسال اشعار الى: {{ payload.patientFullname }}
        </h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-alert dense outlined type="error" v-if="errorAlrt">
          حدث خطاء فشل في العملية
        </v-alert>
        <v-alert dense outlined type="warning" v-if="warningArlt">
          لايمكن ارسال اشعار للمريض جهازه غير معرف لدينا
        </v-alert>
        <v-alert dense outlined type="success" v-if="successAlrt">
          العملية تمت بنجاح بانتظار موافقة مدير النظام
        </v-alert>
        <v-form ref="notification_form" lazy-validation>
          <v-text-field
            readonly
            label="عنوان الاشعار"
            :value="payload.doctorFullname"
            :rules="requiredRule"
          >
          </v-text-field>
          <v-textarea
            label="نص الرسالة"
            :rules="requiredRule"
            v-model="Info.body"
            rows="3"
          ></v-textarea>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="indigo white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="sendNotification"
        >
          ارسال
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
        <v-btn color="primary" text @click="closeDialog">
          اغلاق
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["NotificationDialog", "payload"],
  data() {
    return {
      requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
      loadingSpinner: false,
      errorAlrt: false,
      successAlrt: false,
      warningArlt: false,
      Info: { title: null, body: null, patientId: null },
    };
  },

  methods: {
    ...mapActions(["sendSingleNotification"]),
    closeDialog() {
      this.successAlrt = false;
      this.warningArlt = false;
      this.errorAlrt = false;
      this.$emit("singleNotificationDialogClose", false);
    },
    sendNotification() {
      if (!this.$refs.notification_form.validate()) return;
      if (this.payload.deviceToken == "" || this.payload.deviceToken == null) {
        this.warningArlt = true;
        return;
      }
      this.Info.title = this.payload.doctorFullname;
      this.Info.patientId = this.payload.patientId;
      this.loadingSpinner = true;

      this.sendSingleNotification(this.Info)
        .then((sc) => {
          if (sc == 200) {
            this.loadingSpinner = false;
            this.successAlrt = true;
            this.warningArlt = false;
            this.errorAlrt = false;
            setTimeout(() => {
              this.Info.title = null;
              this.Info.body = null;
              this.Info.patientId = null;
              this.Info.deviceToken = null;
              this.successAlrt = false;
              this.warningArlt = false;
              this.errorAlrt = false;
              this.$emit("singleNotificationDialogClose", false);
            }, 3000);
          }
        })
        .catch(() => {
          this.errorAlrt = true;
          this.successAlrt = false;
          this.warningArlt = false;
          this.loadingSpinner = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes loader {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}
</style>

import { render, staticRenderFns } from "./AddPatientChronicDrugs.vue?vue&type=template&id=2038088e&scoped=true"
import script from "./AddPatientChronicDrugs.vue?vue&type=script&lang=js"
export * from "./AddPatientChronicDrugs.vue?vue&type=script&lang=js"
import style0 from "./AddPatientChronicDrugs.vue?vue&type=style&index=0&id=2038088e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2038088e",
  null
  
)

export default component.exports
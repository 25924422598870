<template>
  <v-navigation-drawer
    :value="drawer"
    app
    permanent
    right
    :mini-variant="mini"
    style="background-color: #f6f6f7"
  >
    <v-list nav dense>
      <v-list-item class="mb-1">
        <v-icon class="mt-2 cblack" @click="mini = !mini">mdi-menu</v-icon>
        <div>
          <strong v-if="!mini" class="float-left pa-3"></strong>
        </div>
      </v-list-item>

      <v-list-item
        v-for="(item, index) in computedSidebarItems"
        :key="index"
        link
        :to="item.route"
        class="mb-3 mb-md-4"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>

      <v-list-item @click="logoutFunc">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title> خروج </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "NavigationDrawer",
    data() {
      return {
        mini: true,
        drawer: true,
        items: [
          { title: "الرئيسية", icon: "mdi-home", route: "main" },
          {
            title: "سجل الزيارات",
            icon: "mdi-list-box",
            route: "reservations",
          },
          {
            title: "القسم المالي",
            icon: "mdi-cash",
            route: "payments",
          },
          {
            title: "سجل المزمنة",
            icon: "mdi-book-account",
            route: "chronicBook",
          },
          {
            title: "العروض",
            icon: "mdi-bullhorn",
            route: "offers",
          },
          {
            title: "النصائح الطبية",
            icon: "mdi-lightbulb",
            route: "advices",
          },

          {
            title: "الاشعارات",
            icon: "mdi-bell",
            route: "notifications",
          },

          {
            title: "الادارة",
            icon: "mdi-account-edit",
            route: "info",
          },
        ],
      };
    },
    computed: {
      ...mapGetters(["getSubscriptionType", "getRole"]),
      computedSidebarItems() {
        if (this.getRole === "clinic_assistant") {
          return this.items.filter((item) => {
            const allowedItems = ["info", "reservations", "main","chronicBook"];
            return allowedItems.includes(item.route);
          });
        } else if (
          this.getSubscriptionType == "inactive" ||
          this.getSubscriptionType == "expired"
        )
          return this.items.filter((item) => item.title === "الرئيسية");
        else if (this.getSubscriptionType == "golden")
          return this.items.filter((item) => item.title !== "النصائح الطبية");
        else if (
          this.getSubscriptionType == "platinum" ||
          this.getSubscriptionType == "diamond"
        )
          return this.items;
        else return this.items;
      },
    },
    methods: {
      ...mapActions(["signout"]),
      logoutFunc() {
        if (localStorage.getItem("user_token"))
          this.signout().then(() => {
            this.$router.push("/");
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .list-item-style {
    font-size: 11px !important;
    font-weight: light;
  }
</style>

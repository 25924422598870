import axios from "@/axios";
const state = {
};

const getters = {};

const actions = {
  async changePassword(_, info) {
    const response = await axios.post("/auth/users/changePassword", info);
    if (response.data.message == "updated") {
      return "updated";
    } else return "failed";
  }
};
const mutations = {

};

export default {
  state,
  getters,
  actions,
  mutations
};

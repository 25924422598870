<template>
  <div class="userinfo">
    <v-container>
      <!--  <div class="title mb-6 text-right" style="font-family: cairo!important;">
        ادارة الحساب
      </div> -->
      <v-row>
        <v-col cols="12 text-right">
          <v-btn
            color="grey darken-1 white--text"
            elevation="0"
            class="ml-1 mb-2"
            @click="showChangePasswordDialog()"
          >
            <v-icon left dark> mdi-lock </v-icon>
            <span class="d-none d-md-inline">تعديل كلمة السر</span>
            <span class="d-inline d-md-none">كلمة السر</span>
          </v-btn>

          <v-btn
            v-if="getUser.role == 'doctor'"
            class="ml-1 mb-2"
            color="orange darken-1 white--text"
            elevation="0"
            @click="showEditClinicDialog()"
          >
            <v-icon left>mdi-pencil</v-icon>
            <span class="d-none d-md-inline">معلومات العيادة</span>
            <span class="d-inline d-md-none">معلومات العيادة</span>
          </v-btn>
          <v-btn
            v-if="getUser.role == 'doctor'"
            class="ml-1 mb-2"
            color="blue darken-1 white--text"
            elevation="0"
            @click="showEditDoctorDialog()"
          >
            <v-icon left>mdi-doctor</v-icon>
            <span class="d-none d-md-inline">معلومات الطبيب</span>
            <span class="d-inline d-md-none">معلومات الطبيب</span>
          </v-btn>
          <v-btn
            v-if="getRole != 'clinic_assistant'"
            class="green white--text ml-1 mb-2"
            dark
            @click="AddCreditCardDialog = true"
            ><v-icon left>mdi-credit-card-plus-outline</v-icon>
            <span class="d-none d-md-inline">اضافة بطاقة دفع</span>
            <span class="d-inline d-md-none">بطاقة دفع</span>
          </v-btn>
        </v-col>
      </v-row>
      <!-- 
      <v-alert dense outlined type="error" v-if="errorAlrt">
        حدث خطاء فشل في العملية
      </v-alert>
      <v-alert dense outlined type="success" v-if="successAlrt">
        عملية التحديث تمت بنجاح
      </v-alert> -->
      <v-row>
        <v-col cols="12" sm="6" md="8" class="order-2 order-sm-1">
          <v-list two-line class="text-right" color="transparent">
            <v-subheader>معلومات الحساب</v-subheader>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>اسم العيادة</v-list-item-title>
                <v-list-item-subtitle>{{ clinicName }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>الاسم الكامل</v-list-item-title>
                <v-list-item-subtitle>{{
                  getUser.fullname
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>اسم المستخدم</v-list-item-title>
                <v-list-item-subtitle>{{
                  getUser.username
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>عنوان الطبيب</v-list-item-title>
                <v-list-item-subtitle>{{
                  getUser.address
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>رقم الهاتف</v-list-item-title>
                <v-list-item-subtitle>{{
                  getUser.mobile
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>تاريخ الانشاء</v-list-item-title>
                <v-list-item-subtitle dir="ltr">{{
                  getUser.created_at.substring(0, 10)
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          class="order-sm-2 pl-4 order-1 px-1 px-sm-2"
          v-if="getUser.creditCards.length > 0"
        >
          <div
            v-for="(credit, index) in getUser.creditCards"
            :key="index"
            class="credit-card mb-0"
          >
            <div class="mb-5 d-block">
              <v-icon class="float-left" size="30" color="grey lighten-3"
                >mdi-credit-card</v-icon
              >
              <div class="text-right inline-block">
                {{ credit.card_type.arabic_name }}
              </div>
            </div>

            <div class="mb-1 text-center">{{ credit.holder_fullname }}</div>
            <h3 class="text-center white--text card-number">
              {{ credit.card_number }}
            </h3>
          </div>
          <!-- credit end -->
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-none d-md-block order-2 align-center py-3"
        >
          <img src="../../../public/mpc_logo.png" width="60%" />
        </v-col>
      </v-row>
    </v-container>
    <change-password
      :ChangePasswordDialog="ChangePasswordDialog"
      @changePasswordDialogClose="ChangePasswordDialog = $event"
    />
    <edit-clinic
      :EditClinicDialog="EditClinicDialog"
      :clinicData="clinicData"
      @editClinicDialogClose="EditClinicDialog = $event"
    />
    <edit-doctor
      :EditDoctorDialog="EditDoctorDialog"
      :doctorData="doctorData"
      @editDoctorDialogClose="EditDoctorDialog = $event"
    />
    <add-credit-card
      :AddCreditCardDialog="AddCreditCardDialog"
      @addCreditCardDialogClose="AddCreditCardDialog = $event"
    />
  </div>
</template>

<script>
  import ChangePassword from "../../components/models/global/ChangePassword";
  import EditClinic from "../../components/models/clinic/EditClinic";
  import EditDoctor from "../../components/models/doctor/EditDoctor";
  import AddCreditCard from "../../components/models/global/AddCreditCard";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "userInfo",
    components: { ChangePassword, EditClinic, EditDoctor, AddCreditCard },
    data() {
      return {
        AddCreditCardDialog: false,
        ChangePasswordDialog: false,
        errorAlrt: false,
        successAlrt: false,
        EditClinicDialog: false,
        EditDoctorDialog: false,
        userInfo: {},
        clinicId: 0,
        userId: 0,
        clinicData: {},
        doctorData: {},
        newDoctorStatus: null,
        clinicName: null,
      };
    },
    computed: {
      ...mapGetters(["getClinic", "getRole", "getDoctor", "getUser"]),
      hasCards() {
        return this.getUser.creditCards.length > 0 ? true : false;
      },
    },
    methods: {
      ...mapActions(["setClinic", "setDoctor"]),

      showChangePasswordDialog: function () {
        this.ChangePasswordDialog = true;
      },
      showEditClinicDialog: function () {
        this.clinicData = this.getClinic;
        this.EditClinicDialog = true;
      },
      showEditDoctorDialog: function () {
        this.doctorData = this.getDoctor;
        this.EditDoctorDialog = true;
      },
    },
    created() {
      console.log(this.getUser);
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.clinicId = this.userInfo.clinic_id;
      this.userId = this.userInfo.id;
      if (this.getRole !== "clinic_assistant") {
        this.setClinic(this.clinicId);
        this.clinicName = this.getClinic.clinic_name;
      } else
        this.clinicName = this.userInfo.clinicAssistantInfo.clinic.clinic_name;
      if (this.getUser.role == "doctor") this.setDoctor();
    },
  };
</script>

<style lang="scss" scopped>
  .userinfo {
    max-height: 85vh;
    overflow-y: scroll;

    .credit-card {
      color: #fff;
      border-radius: 10px;
      padding: 15px 20px;
      background-image: linear-gradient(
        to right top,
        #8272ff,
        #7785ff,
        #7196ff,
        #73a4ff,
        #7db2ff
      );
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
      .card-number {
        font-family: "Courier New", Courier, monospace;
        font-weight: lighter;
      }
    }
  }
</style>

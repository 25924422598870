import axios from "@/axios";
const state = {
  offersSubscriptions: {}
};

const getters = {
  allOffersSubscriptions: state => state.offersSubscriptions.data,
  getOffersSubscriptionsCurrentPage: state => state.offersSubscriptions.current_page,
  getOffersSubscriptionsLastPage: state => state.offersSubscriptions.last_page
};
const actions = {
  async fetchOffersSubscriptions({ commit }, info) {
    const response = await axios.get(
      "/clinic/offers/subscriptions?per_page=10&&page=" +
        info.page +
        "&&search=" +
        info.keyword
    );
    commit("SET_OFFERS_SUBSCRIPTIONS", response.data);
  },
  
  async activateOfferSubscription({ commit }, info) {
    const response = await axios.post(
      "/clinic/offers/subscriptions/" + info.subId + "/activate",info);
      if(response.status==200)
    {
      if(response.data.message=="updated"||response.data.message=="created")
      commit("ACTIVATE_OFFER_SUBSCRIPTION", response.data.data);
      return response.data;
    }

  },
  setOffersSubscriptionsCurrentPage({ commit }, currentPage) {
    commit("SET_OFFERS_SUBSCRIPTIONS_CURRENT_PAGE", currentPage);
  },
 
};
const mutations = {
  ACTIVATE_OFFER_SUBSCRIPTION: (state, data) => {
    const i = state.offersSubscriptions.data
      .map(item => item.id)
      .indexOf(data.id);
    state.offersSubscriptions.data[i].status = data.status;
    state.offersSubscriptions.data[i].payment_method = data.payment_method;
    state.offersSubscriptions.data[i].amount = data.amount;
    state.offersSubscriptions.data[i].from_date = data.from_date;
    state.offersSubscriptions.data[i].expiry_date =
      data.expiry_date;
  },
  SET_OFFERS_SUBSCRIPTIONS: (state, data) => (state.offersSubscriptions = data),
  SET_OFFERS_SUBSCRIPTIONS_CURRENT_PAGE: (state, data) => (state.offersSubscriptions.current_page = data),
};

export default {
  state,
  getters,
  actions,
  mutations
};

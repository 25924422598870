<template>
  <v-dialog
    :value="PrintBadgeDialog"
    v-if="PrintBadgeDialog"
    :width="$vuetify.breakpoint.smAndDown ? '100%' : '75%'"
    persistent
  >
    <v-card v-click-outside="handleClickOutSide">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">طباعة بطاقة الامراض المزمنة</h5>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <div style="display: block">
          <!-- Your ID card template HTML goes here -->
          <div
            style="display: inline-block; margin-left: 20px"
            ref="front_card"
            class="id-card mb-2 blue lighten-4 card-front black--text text-center"
          >
            <section
              class="pdf-item"
              style="margin-bottom: 5px; position: relative"
              dir="rtl"
            >
              <div style="display: block">
                <div style="display: inline-block; margin-left: 5px">
                  <img
                    height="65"
                    width="65"
                    src="../../../assets/imgs/moh_logo.png"
                  />
                </div>
                <div
                  style="
                    display: inline-block;
                    text-align: center;
                    margin-left: 7px;
                  "
                >
                  <h6 style="font-size: 13px">وزارة الصحة</h6>
                  <h6 style="font-size: 13px">دائرة العيادات الطبية الشعبية</h6>
                  <h6 style="font-size: 13px">بطاقة الامراض المزمنة</h6>
                </div>
                <div style="display: inline-block; margin-top: 5px">
                  <img
                    v-if="patientData.profile_picture"
                    height="55"
                    width="55"
                    :src="patientData.profile_picture"
                  />

                  <img v-else height="65" width="65" src="/mpc_logo.png" />
                </div>
              </div>
            </section>
            <section class="pdf-item" style="position: relative" dir="rtl">
              <div style="display: block">
                <div style="display: block; text-align: right" class="px-4">
                  <h6 style="font-size: 12px">
                    الاسم الثلاثي: {{ patientData.fullname }}
                  </h6>
                  <h6 style="font-size: 12px">
                    التولد:
                    <span dir="rtl">{{ patientData.date_of_birth }} </span>
                  </h6>
                  <h6 style="font-size: 12px">
                    الجنس: <span v-if="patientData.gender == 'male'">ذكر</span>
                    <span v-else>انثى</span>
                  </h6>
                  <h6 style="font-size: 12px">
                    رقم الهوية الوطنية:
                    <span v-if="patientData.national_id">{{
                      patientData.national_id
                    }}</span
                    ><span v-else>لايوجد</span>
                  </h6>
                  <h6 style="font-size: 12px">
                    جهة الاصدار: {{ clinicData.clinic_name }}
                  </h6>
                </div>
                <div style="position: absolute; bottom: 10px; left: 15px">
                  <qrcode-vue
                    :value="patientData.uuid"
                    size="55"
                    level="H"
                    background="#fff"
                  />
                </div>
              </div>
            </section>
          </div>
          <div
            style="display: inline-block; position: relative"
            ref="back_card"
            class="id-card px-5 py-5 blue lighten-4 card-back black--text text-center"
          >
            <section class="pdf-item" style="margin-bottom: 5px" dir="rtl">
              <div style="display: block">
                <div style="position: absolute; top: 5px; left: 20px">
                  No: <span>{{ cardNumber }}</span>
                </div>
                <h3 style="text-decoration: underline; text-align: right">
                  ارشادات عامة
                </h3>
                <div style="position: absolute; top: 40px; left: 10px">
                  <img height="80" width="80" src="/mpc_logo.png" />
                </div>
              </div>
            </section>
            <section class="pdf-item" dir="rtl">
              <div style="display: block; text-align: right">
                <h6>1- ابراز البطاقة عند المراجعة.</h6>
                <h6>2- المحافظة على البطاقة من الفقدان.</h6>
                <h6 style="word-break: break-all">
                  3- تذكر دائما موعد استلام الحصة الدوائية.
                </h6>
                <h6>4- الالتزام بأخذ الدواء حسب تعليمات الطبيب.</h6>
              </div>
            </section>
            <section class="pdf-item text-right" dir="rtl">
              <h6 style="font-size: 10px; color: red">
                الخط الساخن (07814859052 - 07712082129)
              </h6>
            </section>
            <section class="pdf-item" dir="rtl">
              <h6 style="font-size: 10px">
                <span class="mx-2"
                  >Issue Date:<span>{{ issueDate }}</span></span
                >
                <span class="mx-2"
                  >Exp. Date: <span>{{ expirationDate }}</span></span
                >
              </h6>
            </section>
          </div>
        </div>
        <!--  <div class="my-2">
          <v-form ref="card_number_form" lazy-validation>
            <v-text-field
              clearable
              :rules="requiredRule"
              v-model="cardNumber"
              label="رقم البطاقة"
            ></v-text-field>
          </v-form>
        </div> -->
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="mb-2 mb-md-0"
          color="blue white--text"
          :loading="lsPrint"
          :disabled="lsPrint"
          @click="printCardFront"
        >
          طباعة 1
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
          <v-icon right>mdi-printer</v-icon>
        </v-btn>
        <v-btn
          class="mb-2 mb-md-0"
          color="blue white--text"
          :loading="lsPrint"
          :disabled="lsPrint"
          @click="printCardBack"
        >
          طباعة 2
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
          <v-icon right>mdi-printer</v-icon>
        </v-btn>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <v-snackbar v-model="snackbar">
      الرجاء ملئ جميع الحقول
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          اغلاق
        </v-btn>
      </template>
    </v-snackbar>
    <div class="d-none">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="true"
        filename="بطاقة المراجع"
        :pdf-quality="2"
        :paginate-elements-by-height="204"
        :manual-pagination="true"
        pdf-format="credit-card"
        pdf-orientation="landscape"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="frontCard"
        @hasDownloaded="attemptPrint($event)"
        pdf-content-width="325px"
        pdf-content-height="204px"
      >
        <section
          slot="pdf-content"
          class="text-center"
          style="
            width: 100%;
            height: 204px;
            padding: 11px;
            background-image: url('/card_front.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          "
        >
          <section
            class="pdf-item"
            style="position: relative; margin-bottom: 2px"
            dir="rtl"
          >
            <div style="display: block">
              <div style="display: inline-block; margin-left: 5px">
                <img
                  height="65"
                  width="65"
                  src="../../../assets/imgs/moh_logo.png"
                />
              </div>
              <div
                style="
                  display: inline-block;
                  text-align: center;
                  margin-left: 10px;
                "
              >
                <h6 style="font-size: 13px">وزارة الصحة</h6>
                <h6 style="font-size: 13px">دائرة العيادات الطبية الشعبية</h6>
                <h6 style="font-size: 13px">بطاقة الامراض المزمنة</h6>
              </div>
              <div style="display: inline-block">
                <img
                  v-if="patientData.profile_picture"
                  height="58"
                  width="58"
                  :src="patientData.profile_picture"
                />

                <img v-else height="65" width="65" src="/mpc_logo.png" />
              </div>
            </div>
          </section>
          <section class="pdf-item" style="position: relative" dir="rtl">
            <div style="display: block">
              <div style="display: inline-block; text-align: right">
                <h6 style="font-size: 12px">
                  الاسم الثلاثي: {{ patientData.fullname }}
                </h6>
                <h6 style="font-size: 12px">
                  التولد:
                  <span dir="rtl">{{ patientData.date_of_birth }} </span>
                </h6>
                <h6 style="font-size: 12px">
                  الجنس: <span v-if="patientData.gender == 'male'">ذكر</span>
                  <span v-else>انثى</span>
                </h6>
                <h6 style="font-size: 12px">
                  رقم الهوية الوطنية:
                  <span v-if="patientData.national_id">{{
                    patientData.national_id
                  }}</span
                  ><span v-else>لايوجد</span>
                </h6>
                <h6 style="font-size: 12px">
                  جهة الاصدار: {{ clinicData.clinic_name }}
                </h6>
              </div>
              <div style="position: absolute; bottom: 10px; left: 10px">
                <qrcode-vue
                  :value="patientData.uuid"
                  size="55"
                  level="H"
                  background="#f4f6fa"
                />
              </div>
            </div>
          </section>
        </section>
      </vue-html2pdf>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="true"
        filename="بطاقة المراجع"
        :pdf-quality="2"
        :paginate-elements-by-height="204"
        :manual-pagination="true"
        pdf-format="credit-card"
        pdf-orientation="landscape"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="backCard"
        @hasDownloaded="attemptPrint($event)"
        pdf-content-width="325px"
        pdf-content-height="204px"
      >
        <section
          slot="pdf-content"
          class="text-center"
          style="
            width: 100%;
            height: 204px;
            padding: 10px;
            padding-right: 15px;
            background-image: url('/card_back.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
          "
        >
          <section class="pdf-item" dir="rtl">
            <div style="display: block; position: relative">
              <div style="position: absolute; top: 3px; left: 10px">
                No: <span>{{ cardNumber }}</span>
              </div>
              <h2
                style="
                  font-size: 14px;
                  text-decoration: underline;
                  text-align: right;
                  margin-bottom: 5px;
                "
              >
                ارشادات عامة
              </h2>

              <div style="position: absolute; top: 40px; left: 8px">
                <img height="80" width="80" src="/mpc_logo.png" />
              </div>
            </div>
          </section>
          <section class="pdf-item" dir="rtl" style="margin-bottom: 5px">
            <div style="display: block; text-align: right">
              <h6>1- ابراز البطاقة عند المراجعة.</h6>
              <h6>2- المحافظة على البطاقة من الفقدان.</h6>
              <h6 style="word-break: break-all">
                3- تذكر دائما موعد استلام الحصة الدوائية.
              </h6>
              <h6>4- الالتزام بأخذ الدواء حسب تعليمات الطبيب.</h6>
            </div>
          </section>
          <section class="pdf-item" style="margin-bottom: 10px" dir="rtl">
            <h6 style="font-size: 10px; color: red">
              الخط الساخن (07814859052 - 07712082129)
            </h6>
          </section>
          <section class="pdf-item" dir="rtl">
            <h6 style="font-size: 10px; text-align: center">
              <span class="mx-4" style="margin-right: 10px"
                >Issue Date:<span>{{ issueDate }}</span></span
              >

              <span class="mx-4" style="margin-left: 10px">
                Exp. Date: <span>{{ expirationDate }}</span></span
              >
            </h6>
          </section>
        </section>
      </vue-html2pdf>
    </div>
  </v-dialog>
</template>

<script>
  import VueHtml2pdf from "vue-html2pdf";
  import QrcodeVue from "qrcode.vue";

  export default {
    components: { VueHtml2pdf, QrcodeVue },
    props: [
      "PrintBadgeDialog",
      "patientData",
      "clinicData",
      "issueDate",
      "expirationDate",
      "cardNumber",
    ],
    data() {
      return {
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        base64Image: null,
        snackbar: false,
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        loadingSpinner: false,
        lsPrint: false,
        errorAlrt: false,
        successAlrt: false,
      };
    },
    mounted() {
      this.base64Image = localStorage.getItem("profile_picture");
    },
    methods: {
      handleClickOutSide(e) {
        if (e.target.className == "v-overlay__scrim") this.closeDialog();
      },

      closeDialog() {
        this.$emit("printBadgeDialogClose", false);
      },

      attemptPrint(blob) {
        let url = URL.createObjectURL(blob);
        window.open(url); //opens the pdf in a new tab
      },
      printCardFront() {
        this.base64Image = localStorage.getItem("profile_picture");
        this.lsPrint = true;
        this.$refs.frontCard.generatePdf();
        this.lsPrint = false;
      },
      printCardBack() {
        this.lsPrint = true;
        this.$refs.backCard.generatePdf();
        this.lsPrint = false;
      },
    },
  };
</script>

<style scoped>
  .card-front {
    background-image: url("../../../assets/imgs/card_front.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    height: 204px;
    width: 325px;
  }
  .card-back {
    background-image: url("/public/card_back.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 15px;
    height: 204px;
    width: 325px;
  }
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
</style>

<template>
  <div class="main" dir="rtl">
    <v-container fluid>
      <v-row class="mb-3">
        <v-col cols="12" sm="6" md="6" class="ml-0">
          <div class="title mb-3" style="font-family: cairo !important">
            الاحصائيات
          </div>
          <v-card
            v-for="card in filteredStatisticsCards"
            :key="card.title"
            class="mx-right pt-3 mb-2 ml-2 float-right"
            color="white"
            width="250"
            outlined
            :to="card.route"
            v-if="
              (isClinicAdmin && card.type == 'clinicAdmin') ||
              card.type == 'doctor'
            "
          >
            <v-row class="pa-2">
              <v-col cols="12" class="mb-2">
                <span
                  style="padding: 6px 8px; border-radius: 5px"
                  :class="card.color"
                >
                  <v-icon color="white">{{ card.icon }}</v-icon>
                </span>
              </v-col>

              <v-col cols="12">
                <div class="mb-2" style="font-weight: bold">
                  {{ card.title }}
                </div>
                <div class="mb-3">
                  {{ getCounters[card.countName] }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col
          v-if="getUser.role != 'clinic_assistant'"
          cols="12"
          sm="6"
          md="4"
        >
          <div class="title mb-2" style="font-family: cairo !important">
            اختصارات سريعة
          </div>
          <!--   <v-col cols="12" class="text-right">
            <div
              style="cursor: pointer"
              class="d-inline-block pa-1"
              ref="qrCodeSection"
              @click="downloadQr"
            >
              <qrcode-vue
                v-if="getUser.role != 'clinic_assistant'"
                :value="qrDoctorId"
                :size="qrSize"
                level="H"
                background="#f4f6fa"
              />
            </div>
          </v-col> -->
          <v-col cols="12" class="float-right pr-0 py-1">
            <v-card color="amber lighten-5 mb-4" max-width="250">
              <v-card-text class="px-3 py-2">
                <v-avatar color="amber lighten-2" size="40">
                  <v-icon size="large" color="grey lighten-5"
                    >mdi-bell-ring</v-icon
                  >
                </v-avatar>
                <span class="mr-2">الاشعارات</span>
                <v-btn
                  class="mt-2"
                  color="amber  lighten-1 white--text"
                  elevation="0"
                  @click="NotificationsDialog = true"
                  text
                >
                  <v-icon left>mdi-send</v-icon>
                  ارسال اشعارات
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="float-right pr-0 py-1">
            <v-card color="indigo lighten-5 mb-4" max-width="250">
              <v-card-text class="px-3 py-2">
                <v-avatar color="indigo lighten-2" size="40">
                  <v-icon size="large" color="grey lighten-5"
                    >mdi-brightness-percent</v-icon
                  >
                </v-avatar>
                <span class="mr-2">العروض والاشتراكات</span>
                <v-btn
                  class="mt-2 d-block"
                  color="indigo  lighten-1 white--text"
                  elevation="0"
                  @click="OffersDialog = true"
                  text
                >
                  <v-icon left>mdi-plus</v-icon>
                  اضافة عرض
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>

          <!--  <v-col cols="12" class="float-right pr-0 py-1">
            <v-card color="red lighten-1 mb-4" max-width="250">
              <v-card-text class="px-3 py-2">
                <v-avatar color="red darken-3" size="40">
                  <v-icon size="large" color="grey lighten-5"
                    >mdi-credit-card-settings-outline</v-icon
                  >
                </v-avatar>
                <span class="mr-2 white--text">صفحة الاشتراكات</span>
                <v-btn
                  class="mt-2 d-block"
                  color="white  lighten-1 white--text"
                  elevation="0"
                  @click="showOffersSubscriptionsPage"
                  text
                >
                  <v-icon left>mdi-arrow-right</v-icon>
                  الاشتراكات
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col> -->
        </v-col>
        <v-col v-else cols="12" md="6" class="pa-10 d-none d-md-block">
          <v-img src="@/assets/imgs/main-avatar2.png"></v-img>
        </v-col>
      </v-row>

      <!-- 	<v-row>
				<v-col cols="12" md="6">
					<v-card class="mt-4 ml-auto" max-width="400">
						<v-sheet
							class="v-sheet--offset mx-auto"
							color="indigo lighten-2"
							elevation="12"
							max-width="calc(100% - 32px)"
						>
							<v-sparkline
								:labels="labels"
								:value="value"
								color="white"
								line-width="2"
								padding="16"
							></v-sparkline>
						</v-sheet>

						<v-card-text class="pt-0">
							<div class="text-h6 font-weight-light mb-2">
								مراجعين هذا الشهر
							</div>
							<div class="subheading font-weight-light grey--text">
								Last Update
							</div>
							<v-divider class="my-2"></v-divider>
							<v-icon class="mr-2" small>
								mdi-clock
							</v-icon>
							<span class="text-caption grey--text font-weight-light"
								>last registration 26 minutes ago</span
							>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row> -->
    </v-container>
    <send-notifications
      :NotificationsDialog="NotificationsDialog"
      @notificationsDialogClose="NotificationsDialog = $event"
    />
    <add-offer
      :OffersDialog="OffersDialog"
      @offersDialogClose="OffersDialog = $event"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import SendNotifications from "../../components/models/notifications/SendNotifications";
  import AddOffer from "../../components/models/notifications/AddOffer";
  import QrcodeVue from "qrcode.vue";
  import html2canvas from "html2canvas";
  export default {
    name: "mainPage",
    components: { SendNotifications, AddOffer, QrcodeVue },

    computed: {
      ...mapGetters(["getCounters", "getUser", "getRole", "isClinicAdmin"]),
      filteredStatisticsCards() {
        // If the user's role is "clinic_assistant," only include the "الزيارات المرضية" card
        if (this.getUser.role === "clinic_assistant") {
          return this.statisticsCards.filter(
            (card) => card.title === "زيارات العيادة"
          );
        }
        return this.statisticsCards;
      },
      qrDoctorId() {
        return this.getUser.doctorInfo.id.toString();
      },
    },
    created() {
      this.fetchCounters();
    },
    methods: {
      ...mapActions(["fetchCounters"]),
      showOffersSubscriptionsPage() {
        this.$router.push({ name: "OffersSubscriptionsPage" });
      },
      async downloadQr() {
        const el = this.$refs.qrCodeSection;

        const options = {
          type: "dataURL",
        };
        const printCanvas = await html2canvas(el, options);

        const link = document.createElement("a");
        link.setAttribute("download", "doctorQrCode.png");
        link.setAttribute(
          "href",
          printCanvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream")
        );
        link.click();
      },
    },

    data() {
      return {
        NotificationsDialog: false,
        OffersDialog: false,
        value: [423, 446, 675, 510, 590, 610, 760],
        search: "",
        labels: ["12am", "3am", "6am", "9am", "12pm", "3pm", "6pm", "9pm"],
        qrSize: 110,
        statisticsCards: [
          {
            icon: "mdi-hospital-building",
            title: "زيارات العيادة",
            countName: "clinic_reservations",
            color: "red lighten-1",
            route: "reservations",
            type: "doctor",
          },

          {
            icon: "mdi-lightbulb",
            title: "مجموع النصائح",
            countName: "medicalAdvices",
            color: "green lighten-2",
            route: "advices",
            type: "doctor",
          },
          {
            icon: "mdi-bullhorn",
            title: "مجموع العروض",
            countName: "offers",
            color: "indigo lighten-2",
            route: "offers",
            type: "doctor",
          },
          {
            icon: "mdi-bell",
            title: "مجموع الاشعارات",
            countName: "notifications",
            color: "amber lighten-2",
            route: "notifications",
            type: "doctor",
          },
          {
            icon: "mdi-doctor",
            title: "عدد الاطباء",
            countName: "doctors",
            color: "blue lighten-2",
            route: "#",
            type: "clinicAdmin",
          },
          {
            icon: "mdi-test-tube",
            title: "عدد الفحوصات",
            countName: "analyses",
            color: "purple lighten-2",
            route: "#",
            type: "clinicAdmin",
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped>
  .main {
    max-height: 82vh;
    overflow: auto;
  }
</style>

<template>
  <v-dialog v-if="EditPatientDialog" :value="EditPatientDialog" width="500">
    <v-card v-click-outside="closeDialog">
      <v-card-title class="headline mb-3">
        <h5 class="text-right pr-1">تعديل معلومات المراجع</h5>
      </v-card-title>
      <v-card-text class="text-center mb-2">
        <v-alert dense outlined :type="alrtType" v-if="alrtState">
          {{ alrtMsg }}
        </v-alert>

        <v-form ref="patient_form" lazy-validation>
          <v-text-field
            label="الاسم"
            :rules="requiredRule"
            v-model="patientData.fullname"
            outlined
          >
          </v-text-field>
        </v-form>
        <v-btn
          color="indigo white--text"
          :loading="loadingSpinner"
          :disabled="loadingSpinner"
          @click="updatePatientFn"
        >
          تعديل
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> اغلاق </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions } from "vuex";

  export default {
    props: ["EditPatientDialog", "patientObject"],

    watch: {
      patientObject(new_value) {
        this.patientData = Object.assign({}, new_value);
      },
    },

    data() {
      return {
        patientData: Object.assign({}, this.patientObject),
        requiredRule: [(v) => !!v || "هذا الحقل مطلوب"],
        loadingSpinner: false,
        alrtState: false,
        alrtType: "",
        alrtMsg: "",
        Info: { fullname: null, patientId: 0, _method: "put" },
      };
    },

    methods: {
      ...mapActions(["updatePatient"]),
      closeDialog() {
        this.$emit("editPatientDialogClose", false);
      },

      updatePatientFn() {
        if (!this.$refs.patient_form.validate()) return;

        this.Info.patientId = this.patientData.id;
        this.Info.fullname = this.patientData.fullname;
        this.loadingSpinner = true;

        this.updatePatient(this.Info)
          .then(() => {
            this.loadingSpinner = false;
            this.alrtState = true;
            this.alrtType = "success";
            this.alrtMsg = "عملية التعديل تمت بنجاح";
            setTimeout(() => {
              this.alrtState = false;
              this.alrtType = "";
              this.alrtMsg = "";
              this.closeDialog();
            }, 3000);
          })
          .catch(() => {
            this.alrtState = true;
            this.alrtType = "error";
            this.alrtMsg = "فشل في العملية حاول لاحقا";
            this.loadingSpinner = false;
          });
      },
    },
  };
</script>

<style scoped lang="scss">
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }
  }
</style>
